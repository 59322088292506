import Amenity from '../shared/amenity'
import FetchCall from '../shared/local_fetch_call'
import CheckType from '../shared/add_to_cart_forms/h4h_rooms/h4h_global_functions'
import Room from './room'
export default class Hotel {
  constructor(json, contracted = false, cusRoomcashAmount) {
    this.arnPrice = null
    this.directions=null
    this.contracted = contracted
    this.checkIn = null
    this.checkOut = null
    this.stayDuration = null
    this.latitude = null
    this.longitude = null
    this.starRating = null
    this.addressOne = null
    this.addressTwo = null
    this.city = null
    this.state = null
    this.zip = null
    this.country = null
    this.locationDescription = null
    this.checkInTime = null
    this.checkOutTime = null
    this.phone = null
    this.email = null
    this.petsAllowed = null
    this.images = null
    this.thumbnailImage = null
    this.description = null
    this.rating = null
    this.reviews = null
    this.type = null
    this.tbdDates = false
    this.rewardPoints = 0

    if (contracted) {
      this.id = json.arn_id
      this.qng_id = json.id
      this.transportationFee = json.transportation_fee
      this.name = json.display_name_with_fallback
      this.rooms = [] // Stays
      this.stayDuration = json.number_of_nights
      this.directions = json.distance_from_venue

      for(let i = 0; i < json.stays.length; i++) {
        this.rooms.push(new Room(json.stays[i], true))
      }
    } else {
      // Returned in the Hotel Availability search
      this.id = json['@HotelID']
      this.name = json['@Name']
      this.rewardPoints = json['@RoomcashAmount'] || 0
      this.rooms = []
      this.discountedRooms = []
      this.nonDiscountedRooms = []
      this.directions = json['distance_from_venue']

      let ratePlan = CheckType.checkType(json.RatePlan)
      ratePlan.forEach(plan => {
        if(plan){
          let rooms = CheckType.checkType(plan.Room)
          rooms.forEach(room => {
            if(room){
              let newRoom = new Room(room, false, plan['@Code'], plan['@Gateway'], cusRoomcashAmount)
              this.addRoomcash(room, cusRoomcashAmount)
              if (newRoom.rewardPoints > 0) {
                this.discountedRooms.push(newRoom)
              } else {
                this.nonDiscountedRooms.push(newRoom)
              }
            }
          })
        }
      })
      this.discountedRooms.sort((a,b)=> a.nightlyRate - b.nightlyRate )
      this.rooms = this.discountedRooms.concat(this.nonDiscountedRooms)
    }
  }

  static from(json) {
    return Object.assign (new Hotel(), json)
  }

  get fullAddress() {
    let street = [this.addressOne, this.addressTwo, this.city, this.state].join(' ')
    return this.zip ? (street + ', ' + this.zip) : street
  }

  lowerCaseAll(list){
    list = list.map(function(x){return x.toLowerCase()})
    return list
  }

  addRoomcash(room, cusRoomcashAmount){
    if(room['@Discount'] && room['@Discount'] > 0 && room['@Discount'] > this.rewardPoints){
      this.rewardPoints = Math.min(Math.ceil(room['@Discount']), 50, cusRoomcashAmount)
    }
  }

  async updateHotelInfo() {
    let res = await FetchCall.fetchWithoutLocale(
      `/api/h4h_hotels/${this.id}`,
      'GET',
      null
    )
    const amenities = this.lowerCaseAll(res.AmenityList)

    this.latitude = res.Latitude
    this.longitude = res.Longitude
    this.starRating = res.PriceClass
    this.addressOne = res.Address1
    this.addressTwo = res.Address2
    this.city = res.City
    this.state = res.State
    this.zip = res.Postal
    this.country = res.CountryCode
    this.locationDescription = res.LocationDescription
    this.checkInTime = res.CheckInTime
    this.checkOutTime = res.CheckOutTime
    this.phone = res.PrimaryPhone
    this.email = res.Email
    this.amenities = res.AmenityList
    this.airportShuttle = Amenity.hasAirportShuttle(amenities)
    this.breakfast = Amenity.includesBreakfast(amenities)
    this.businessCenter = Amenity.hasBusinessCenter(amenities)
    this.fitnessCenter = Amenity.hasFitnessCenter(amenities)
    this.internetAccess = Amenity.includesInternet(amenities)
    this.petsAllowed = Amenity.allowPets(amenities)
    this.pool = Amenity.hasPool(amenities)
    this.restaurant = Amenity.hasRestaurant(amenities)
    this.images = res.Images
    this.thumbnailImage = res.ImageThumbnail
    this.description = res.PropertyDescription
    this.rating = res.TripAdvisorRating
    this.reviews = res.TripAdvisorReviewCount
    this.type = res.PropertyType
  }
}
