<template>
  <div>
    <div class="container hotel-detail">
      <div class="hotel-banner">
        <div class="hotel-name">
          <h2>
            {{ hotel.name || hotel.display_name_with_fallback }}
          </h2>
        </div>
        <div
          v-if="isRoomCash"
          class="start-price"
        >
          <p>
            <span class="starts-at">{{ $t('h4h.startsAt') }}</span>
            <b
              v-if="hasStayDuration && (hotel.rewardPoints > 0)"
              class="bold-price"
            >
              <CurrencyConvert
                :amount="((price / hotel.stayDuration))*order.exchange_rate"
                :usd="((price / hotel.stayDuration))"
                :currency="order.currency"
              />
            </b>
            <b
              v-else-if="hotel.rewardPoints > 0"
              class="bold-price"
            >
              <CurrencyConvert
                :amount="(price - hotel.rewardPoints)*order.exchange_rate"
                :usd="(price - hotel.rewardPoints)"
                :currency="order.currency"
              />
            </b>
            <b
              v-else
              class="bold-price"
            >
              <CurrencyConvert
                :amount="price*order.exchange_rate"
                :usd="price"
                :currency="order.currency"
              />
            </b>
            <span
              v-if="hasStayDuration"
              class="per-night"
            >
              {{ $t('h4h.perNight') }}
            </span>
          </p>
        </div>
        <div
          v-else
          class="start-price"
        >
          <p>
            <span class="starts-at">{{ $t('h4h.startsAt') }}</span>
            <b
              v-if="hasStayDuration"
              class="bold-price"
            >
              <CurrencyConvert
                :amount="(price / hotel.stayDuration)*order.exchange_rate"
                :usd="price / hotel.stayDuration"
                :currency="order.currency"
              />
            </b>
            <b
              v-else
              class="bold-price"
            >
              <CurrencyConvert
                :amount="price*order.exchange_rate"
                :usd="price"
                :currency="order.currency"
              />
            </b>
            <span
              v-if="hasStayDuration"
              class="per-night"
            >
              {{ $t('h4h.perNight') }}
            </span>
          </p>
        </div>
      </div>
      <div
        v-if="contractedHotel && transportationProvided"
        class="contract-featured-box"
      >
        {{ $t('h4h.featuredWithTransportation') }}
      </div>
      <div
        v-else-if="contractedHotel"
        class="contract-featured-box"
      >
        {{ $t('h4h.featured') }}
      </div>
      <div
        v-if="contractedHotel && transportationProvided"
        class="to-from-statement"
      >
        <i>{{ $t('h4h.toFromHotel') }}</i>
      </div>
      <div class="hotel-basic-info">
        <p>{{ hotel.fullAddress }}</p>
        <p v-if="hotel.directions && standardH4HForm">
          {{ hotel.directions }} to {{ $store.state.hotel.venue }}
        </p>
      </div>
    </div>
    <ImageCarousel
      v-if="hotel.images.length > 0"
      :images="hotel.images"
    />
    <div class="hotel-info-amenities-container container">
      <div class="hotel-main-info">
        <h4><b>{{ $t('h4h.chooseARoom') }}</b></h4>
        <div class="checkin-info">
          <div class="stay">
            <p><b>{{ $t('h4h.checkIn') }} - {{ $t('h4h.checkOut') }}</b></p>
            <span>{{ displayDates }}</span>
          </div>
          <RoomQuantity
            v-if="hotel.contracted"
            :contracted="hotel.contracted"
          />
          <div
            v-else
            class="rooms"
          >
            <p>
              <b>{{ $t('h4h.roomNum') }}</b>
            </p>
            <span>
              {{ $store.state.hotel.roomQty }}
            </span>
          </div>
          <br>
          <div
            v-if="isRoomCash"
            class="room-information"
          >
            <RoomcashRetailRoomCard
              v-for="room in hotel.rooms"
              :key="room.index"
              :check-in="hotel.checkIn"
              :check-out="hotel.checkOut"
              :reward-points="hotel.rewardPoints"
              :room="room"
              :stay-duration="hotel.stayDuration"
              :rate="parseFloat(order.exchange_rate)"
              :currency="order.currency"
            />
          </div>
          <div
            v-else-if="hotel.contracted"
            class="room-information"
          >
            <ContractRoomCard
              v-for="room in hotel.rooms"
              :key="room.index"
              :check-in="hotel.checkIn"
              :check-out="hotel.checkOut"
              :room="room"
              :rate="parseFloat(order.exchange_rate)"
              :currency="order.currency"
            />
          </div>
          <div
            v-else
            class="room-information"
          >
            <RetailRoomCard
              v-for="room in hotel.rooms"
              :key="room.index"
              :check-in="hotel.checkIn"
              :check-out="hotel.checkOut"
              :room="room"
              :stay-duration="hotel.stayDuration"
              :rate="parseFloat(order.exchange_rate)"
              :currency="order.currency"
            />
          </div>
          <h4 class="property-description-title">
            <b>{{ $t('h4h.propertyDescription') }}</b>
          </h4>
          <p class="property-description">
            {{ hotel.description }}
          </p>
          <!-- <H4hMap
            :hotel-latitude="parseFloat(hotel.latitude)"
            :hotel-longitude="parseFloat(hotel.longitude)"
            :hotel-name="hotel.name"
            :event-name="order.event_name"
            :event-latitude="order.event_latitude"
            :event-longitude="order.event_longitude"
          /> -->
        </div>
      </div>
      <div class="hotel-side-container">
        <div class="hotel-side-container-child">
          <HotelAmenities
            :amenities="hotel.amenities"
          />
          <Rating
            :rating="hotel.rating"
            :reviews="hotel.reviews"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global locale */

import { CurrencyConvert } from '../../../default/currency_convert'
import ImageCarousel from './hotel_images'
import RoomQuantity from '../h4h_search_form/room_quantity'
import RetailRoomCard from './retail_room_card'
import RoomcashRetailRoomCard from '../roomcash/retail_room_card'
import ContractRoomCard from './contract_room_card'
import HotelAmenities from './hotel_amenities'
import Rating from './hotel_rating'
// import H4hMap from './maps'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { getStayPrice} from '../h4h_hotels/shared_hotel_card_functions'


export default {
  components: {
    CurrencyConvert,
    ImageCarousel,
    RoomQuantity,
    RetailRoomCard,
    RoomcashRetailRoomCard,
    ContractRoomCard,
    HotelAmenities,
    Rating,
    // H4hMap,
  },

  computed: {
    ...mapGetters('hotel', [
      'getLowestValue'
    ]),
    isRoomCash(){
      return (window.brand_name == 'RoomCash')
    },
    standardH4HForm(){
      return !this.order.blank_h4h_form
    },
    hotel() {
      return this.$store.state.hotel.hotel
    },
    contractedHotel(){
      return this.hotel.contracted
    },
    order(){
      return this.$store.state.order.order
    },
    stars() {
      let stars = this.$store.state.hotel.hotel.starRating
      if(stars) stars = stars.charAt(0)
      return parseInt(stars)
    },
    hasStayDuration(){
      return this.hotel.stayDuration > 0
    },
    price() {
      if(this.hotel.contracted){
        return getStayPrice(this.hotel)
      } else {
        return this.getLowestValue(this.hotel.id)
      }
    },
    transportationProvided(){
      return this.hotel.transportationFee > 0
    },
    displayDates() {
      return this.hotel.tbdDates ?
        'TBD' : this.reformatDates()
    },
    rewardPointsPerNight() {
      return (this.hotel.rewardPoints / this.hotel.stayDuration)
    }
  },

  methods:{
    reformatDates(){
      moment.locale(locale)
      let checkin = moment(this.hotel.checkIn).format('L')
      let checkout = moment(this.hotel.checkOut).format('L')
      return `${checkin} - ${checkout}`
    }
  }
}
</script>
