<template>
  <div
    v-if="updateableHotel.contracted != true || showContracted"
    class="retail-hotel-card-container"
  >
    <div class="retail-hotel-card-image-container">
      <div class="retail-hotel-card-badge-container">
        <div
          v-if="updateableHotel.contracted && transportationProvided"
          class="mobile-featured-bar"
        >
          {{ $t('h4h.featuredWithTransportation') }}
        </div>
        <div
          v-else-if="updateableHotel.contracted"
          class="mobile-featured-bar"
        >
          {{ $t('h4h.featured') }}
        </div>
        <div
          v-if="hasExclusiveRates"
          class="mobile-featured-bar"
        >
          {{ $t('h4h.exclusiveRates') }}
        </div>
      </div>
      <ImageSlider
        :hotelid="parseInt(updateableHotel.id)"
        :images="updateableHotel.images"
      />
    </div>
    <RoomcashHotelInfo
      v-if="isRoomCash"
      :hotel="updateableHotel"
      :rate="rate"
      :currency="currency"
    />
    <HotelInfo
      v-else
      :hotel="updateableHotel"
      :rate="rate"
      :currency="currency"
    />
    <!-- </div> -->
  </div>
</template>

<script>

 
import ImageSlider from '../../image_slider'
import Hotel from '../../../classes/hotel'
import { mapGetters, mapMutations } from 'vuex'
import HotelInfo from './hotel_info.vue'
import RoomcashHotelInfo from '../roomcash/hotel_info'

export default {
  components: {
    ImageSlider,
    RoomcashHotelInfo,
    HotelInfo
  },
  props: {
    id: {
      default: null,
      type: Number
    },
    hotel: {
      default: null,
      type: Hotel
    },
    rate: {
      default: 1,
      type: Number
    },
    currency: {
      default: 'USD',
      type: String
    }
  },


  data() {
    return {
      address: String,
      updateableHotel: {}
    }
  },

  computed: {
    ...mapGetters('hotel', ['getLowestValue', 'getTBD']),
    // price() {
    //   return this.getLowestValue(this.hotel.id)
    // },
    showContracted(){
      return (this.eventDatesMatch && this.hotelBehavior != 'retail_only') ||
        this.hotelBehavior == 'contracted_only'
    },
    eventDatesMatch(){
      return this.$store.state.hotel.checkInDate.toString() == this.hotel.checkIn.toString() &&
      this.$store.state.hotel.checkOutDate.toString() == this.hotel.checkOut.toString()
    },
    isRoomCash(){
      return (window.brand_name == 'RoomCash')
    },
    transportationProvided(){
      return this.updateableHotel.transportationFee > 0 ? true : false
    },
    hasExclusiveRates(){
      const targetGateways = ['16', '20', '33']
      return this.updateableHotel.rooms.some(room => targetGateways.includes(room.gateway))
    },
    hotelLocation(){
      return {lat:this.updateableHotel.latitude, lng:this.updateableHotel.longitude}
    },
    hotelBehavior(){
      return this.order.event_hotel_behavior
    },
    order(){
      return this.$store.state.order.order
    },

  },

  watch: {
    hotel() {
      this.updateableHotel = this.hotel
      this.updateableHotel.updateHotelInfo()
    }
  },

  created(){
    this.updateableHotel = this.hotel
    this.updateableHotel.updateHotelInfo()
  },

  methods: {
    ...mapMutations('hotel', ['selectHotel', 'deselectHotel', 'setRetailHotelStatus']),
  }
}
</script>
