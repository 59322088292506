<template>
  <div class="ticket-icon-container">
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 266.1 266.1"
      style="enable-background:new 0 0 266.1 266.1;"
      xml:space="preserve"
      class="ticket-icon"
    >
      <g>
        <rect
          x="43.8"
          y="79.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -55.0565 133.0312)"
          class="st0"
          width="178.6"
          height="107.3"
        />
        <path
          class="st1"
          d="M107.9,226.8l-68.5-68.5L158.1,39.5l68.5,68.5L107.9,226.8z M266.1,95.3L95.3,266.1L62.3,233   c8.1-8.1,8.1-21.1,0-29.2s-21.1-8.1-29.2,0L0,170.8L170.8,0l33.1,33.1c-8.1,8.1-8.1,21.1,0,29.2c8.1,8.1,21.1,8.1,29.2,0   L266.1,95.3z M234.1,107.9L158.2,32L32,158.2l75.9,75.9L234.1,107.9z"
        />
      </g>
    </svg>
  </div>
</template>
