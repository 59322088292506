<template>
  <div
    v-if="(room.ratePlan || room.inventory > 0) && room.active"
    class="retail-room-card-container"
  >
    <div class="retail-room-details">
      <div class="retail-room-title">
        <h4>{{ room.name }}</h4>
      </div>
      <div
        v-if="hasExclusiveRates"
        class="exclusive-rates-badge"
      >
        {{ $t('h4h.exclusiveRates') }}
      </div>
      <p
        v-if="hotel.contracted && notEnoughRooms"
        class="contracted-rooms-remaining"
      >
        {{ room.inventory == 1 ? $t('h4h.roomRemaining', {
          quantity: room.inventory
        }) : $t('h4h.roomsRemaining', {
          quantity: room.inventory
        }) }}
      </p>
      <p>{{ room.description }}</p>
    </div>
    <div class="retail-room-purchasing">
      <div class="retail-room-pricing">
        <span
          class="price"
        >
          <b>
            <CurrencyConvert
              :amount="(room.total / stayDuration) * rate"
              :usd="room.total / stayDuration"
              :currency="currency"
            />
          </b>
        </span>
        <span class="per-night">
          {{ $t('h4h.perNight') }}
        </span>
        <span class="total-price">
          <CurrencyConvert
            :amount="room.total * rate"
            :usd="room.total"
            :currency="currency"
          />
          <span class="hotel-show-stay-length">
            {{ (getRetailHotelStatus || !getTBDStatus) ? $t('h4h.roomTotal', {
              quantity: stayDuration
            }) : $t('h4h.tbd') }}
          </span>
        </span>
        <p
          v-if="hotelPriceChange"
          class="hotel-price-change"
        >
          {{ $t('h4h.priceChange') }}
        </p>
        <p
          v-if="hotelNotFound"
          class="hotel-price-change"
        >
          {{ $t('checkout.payment.hotelNotAvailable') }}
        </p>
      </div>
      <div
        v-if="isXGames()"
        class="btn btn-secondary btn-size"
        :class="disableButton ? 'btn-disabled' : ''"
        :disable="disableButton || disableForSubmission"
        @click="changeOrderItem()"
      >
        <span class="btn-text">{{ $t('h4h.selectRoom') }}</span>
        <div class="arrow" />
      </div>
      <div
        v-else
        class="btn btn-secondary btn-size"
        :class="disableButton ? 'btn-disabled' : ''"
        :disable="disableButton || disableForSubmission"
        @click="changeOrderItem()"
      >
        {{ $t('h4h.selectRoom') }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../cart/cart_api'
import { CurrencyConvert } from '../../../default/currency_convert'
import { mapActions, mapState } from 'vuex'
import { mapMutations } from 'vuex'

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    CurrencyConvert
  },

  props: {
    checkIn: {
      type: Date,
      default: null
    },
    checkOut: {
      type: Date,
      default: null
    },
    hotelId: {
      type: Number,
      default: null
    },
    room: {
      type: Object,
      default: null
    },
    stayDuration: {
      type: Number,
      default: null
    },
    rate: {
      type: Number,
      default: 1
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },

  data() {
    return {
      disableForSubmission: false,
      hotelPriceChange: false,
      hotelNotFound: false
    }
  },

  computed: {
    ...mapGetters('hotel', ['getTBDStatus', 'getRetailHotelStatus']),
    ...mapState('hotel', {
      hotel: state => state.hotel,
      roomQty: state => state.roomQty
    }),
    notEnoughRooms() {
      return this.room.inventory <= this.$store.state.packages.packageQty
    },
    disableButton() {
      let roomInCart = false
      if(this.order.items) {
        roomInCart = this.order.items.find( item =>{
          return item.hotel_reservations.length > 0 && item.hotel_reservations[0].room_code == this.room.roomCode
        })
      }
      return roomInCart || this.disableForSubmission ? true : false
    },
    hasExclusiveRates(){
      const targetGateways = ['16', '20', '33']
      return this.hotel.rooms.some(room => targetGateways.includes(room.gateway))
    },
    order(){
      return this.$store.state.order.order
    }
  },

  created() {
    this.setValues()
  },

  methods: {
    ...mapMutations('cart', [
      'displayCart'
    ]),
    ...mapActions('order', ['getBackendOrder']),

    setValues(){
      let chosenPackage=this.order.items[0]
      this.packageId=chosenPackage.package_id
      this.packageQty=this.order.item_count
      this.orderId=this.order.id
      this.orderItemId=chosenPackage.id
    },

    postRetailBody(amount) {
      return JSON.stringify({
        order_item: {
          city: this.hotel.city,
          contracted: this.hotel.contracted,
          hotel_address: this.hotel.addressOne,
          hotel_address_2: this.hotel.addressTwo,
          hotel_check_in_date: moment(this.checkIn).format('DD/MM/YYYY'),
          hotel_check_out_date:moment(this.checkOut).format('DD/MM/YYYY'),
          hotel_check_in_time: this.hotel.checkInTime,
          hotel_check_out_time: this.hotel.checkOutTime,
          hotel_city: this.hotel.city,
          hotel_country: this.hotel.country,
          hotel_id: this.hotel.id,
          hotel_latitude: this.hotel.latitude,
          hotel_longitude: this.hotel.longitude,
          hotel_name: this.hotel.name,
          hotel_phone: this.hotel.phone,
          hotel_state: this.hotel.state,
          hotel_total_price: amount,
          hotel_zip: this.hotel.zip,
          id: this.orderItemId,
          package_id: this.packageId,
          quantity: this.packageQty,
          room_quantity: this.$store.state.hotel.roomQty,
          room_code: this.room.roomCode,
          rate_plan_code: this.room.ratePlan,
          tax_amount: this.room.taxAmount,
          gateway: this.room.gateway,
          gateway_fee: this.room.gatewayFee,
          room_cost_price: this.room.nightlyRate,
          currency_code: this.room.currencyCode,
          booking_fee: this.room.bookingFee
        }
      })
    },
    postContractedBody(stayId) {
      return JSON.stringify({
        hotel: {
          hotel_id: this.hotel.id,
          room_quantity: this.$store.state.hotel.roomQty,
          stay_id: stayId
        }
      })
    },
    async changeOrderItem() {
      if (this.disableButton || this.disableForSubmission) { return }
      this.disableForSubmission = true
      this.errs = null
      let res = null

      if (this.hotel.contracted) {
        res = await Api.addContractedToCart(
          this.orderItemId,
          this.postContractedBody(this.room.stayId)
        )
        this.getBackendOrder().then( () => this.displayCart())

      } else {
        res = await Api.addH4HHotelToCart(
          this.orderItemId,
          this.postRetailBody(this.room.total)
        )

        if(res.room_updated) {
          /* eslint-disable vue/no-mutating-props */
          this.room.gatewayFee = res.room['GatewayFee']['@Amount']
          this.room.bookingFee = res.room['BookingFee']['@Amount']
          this.room.taxAmount = res.room['Tax']['@Amount']
          this.room.total = res.room['Total']['@Amount']
          this.room.nightlyRate = res.room['GatewayFee']['@Amount']
          this.hotelPriceChange = true
          setTimeout(() => {this.hotelPriceChange = false}, 10000)
        } else if( res.errors) {
          this.hotelNotFound = true
        }
        this.getBackendOrder().then( () => this.displayCart())
      }

      if (!res.id) {
        this.disableForSubmission = false
        this.errs = res.errors || this.$t('formPackage.error')
      }
    },
    isXGames() {
      return (window.name == 'x-games')
    }
  }
}
</script>
