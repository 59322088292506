export default {
  hasAirportShuttle(amenityList) {
    //es6 refactor - ternary expression not working nor default includes() return
    if(amenityList.includes('airport shuttle (for a fee)') || amenityList.includes('airport shuttle (complimentary)')) {
      return true
    } else {
      return false
    }
  },

  includesBreakfast(amenityList) {
    if(amenityList.includes('complimentary breakfast (on site)')) {
      return true
    } else {
      return false
    }
  },

  hasBusinessCenter(amenityList) {
    if(amenityList.includes('business center')) {
      return true
    } else {
      return false
    }
  },

  hasFitnessCenter(amenityList) {
    if(amenityList.includes('fitness center (on site)')) {
      return true
    } else {
      return false
    }
  },

  includesInternet(amenityList) {
    if(amenityList.includes('internet access (on site)') || amenityList.includes('wireless internet access in guest rooms (complimentary)')) {
      return true
    } else {
      return false
    }
  },

  allowPets(amenityList) {
    if(amenityList.includes('pets allowed (contact hotel) (on site)') ||amenityList.includes('pets allowed (on site)')) {
      return true
    } else {
      return false
    }
  },

  hasPool(amenityList) {
    if(amenityList.includes('outdoor pool (on site)') || amenityList.includes('pool (0 indoor)') || amenityList.includes('pool (0 outdoor)')) {
      return true
    } else {
      return false
    }
  },

  hasRestaurant(amenityList) {
    if(amenityList.includes('resturant on site')) {
      return true
    } else {
      return false
    }
  }
}
