<script>

import FetchCall from '../../../shared/local_fetch_call'
import Hotel from '../../../classes/hotel'
import { mapMutations, mapGetters } from 'vuex'
import moment from 'moment'

export default {

  data() {
    return {
      indexFetchInProgress: false,
      amenityValueMap: {
        'reqAirportShuttle': 1,
        'reqFitnessCenter': 3,
        'reqInternet': 4,
        'reqBreakfast': 6,
        'reqPetsAllowed': 7,
        'reqPool': 8,
        'reqRestaurant': 9,
        'reqKitchenKitchenette': 10
      },
      classValueMap: {
        1: '1 Stars',
        2: '2 Stars',
        3: '3 Stars',
        4: '4 Stars',
        5: '5 Stars'
      },
      typeValueMap: {
        1: 'hotel',
        5: 'motel',
        6: 'resort',
        8: 'extended_stay',
        9: 'bed_and_breakfast',
        12: 'vacation_rental'
      }
    }
  },
  computed: {
    order(){
      return this.$store.state.order.order
    }
  },

  methods: {
    ...mapMutations('hotel', [
      'clearNonContractedHotels',
      'setRetailLoading',
      'updateHotelsArray',
      'settotalRetailHotels',
      'setLoading',
      'setContractedHotels'
    ]),
    ...mapGetters('hotel', ['getContractedHotels']),
    removeDuplicates(retailHotels){
      if (this.$store.state.hotel.contractedHotels.length == 0) return retailHotels
      let contractedIds = []
      this.$store.state.hotel.contractedHotels.forEach(hotel => {
        contractedIds.push(hotel.arn_id)
      })

      let displayableHotels = []
      retailHotels.map(hotel =>{
        if(!contractedIds.includes(hotel['@HotelID'])){
          displayableHotels.push(hotel)
        }
      })

      return displayableHotels
    },

    objectifiedExtraSearchParams(){
      if(!this.propertyTypes && !this.propertyAmenities && !this.propertyClasses){
        return null
      } else if(this.propertyTypes){
        return {
          propertyTypes: this.propertyTypes,
          propertyAmenities: this.propertyAmenities,
          propertyClasses: this.propertyClasses
        }
      } else {
        return {
          // if no property type specified, default to 1,6,8
          propertyAmenities: this.propertyAmenities,
          propertyClasses: this.propertyClasses
        }
      }
    },


    async fetchAvailableHotelsByDestination(destination, checkIn, checkOut, roomQty, eventId, extraParams = null) {
      if(this.indexFetchInProgress){return}

      this.indexFetchInProgress = true
      if(this.order.event_hotel_behavior != 'contracted_only'){
        let res = await FetchCall.fetchWithoutLocale(
          '/api/h4h_location_hotels' +
        `?destination=${destination}` +
        `&inDate=${checkIn}` +
        `&outDate=${checkOut}` +
        `&rooms=${roomQty}` +
        `&eventId=${eventId}` +
        this.includeExtraParams(extraParams),
          'GET',
          null
        )

        if (res) {
          this.cusRoomcashAmount = res.cus_roomcash_amount
          if (res.hotels){
            this.hotels = this.removeDuplicates(res.hotels)
          }
        }

        this.createHotelObject(false)
      }
      this.setRetailLoading(false)
      this.indexFetchInProgress = false
    },


    async fetchAvailableHotels(latitude, longitude, checkIn, checkOut, roomQty, eventId, extraParams = null) {
      if(this.indexFetchInProgress){return}

      this.indexFetchInProgress = true
      if(this.order.event_hotel_behavior != 'contracted_only'){
        let res = await FetchCall.fetchWithoutLocale(
          '/api/h4h_hotels' +
        `?latitude=${latitude}` +
        `&longitude=${longitude}` +
        `&inDate=${checkIn}` +
        `&outDate=${checkOut}` +
        `&rooms=${roomQty}` +
        `&eventId=${eventId}` +
        this.includeExtraParams(extraParams),
          'GET',
          null
        )
        // debugger

        if (res) {
          this.cusRoomcashAmount = res.cus_roomcash_amount
          if (res.hotels){
            this.hotels = this.removeDuplicates(res.hotels)
          }
        }

        this.createHotelObject(false)
      }
      this.setRetailLoading(false)
      this.indexFetchInProgress = false
    },

    includeExtraParams(extraParams) {
      if(!extraParams) return ''

      return (`&propertyTypes=${extraParams['propertyTypes']}` +
        `&propertyClasses=${extraParams['propertyClasses']}` +
        `&propertyAmenities=${extraParams['propertyAmenities']}`)
    },
    async createHotelObject(isContracted) {
      this.clearNonContractedHotels()

      let hotelsArray = []
      let discountedHotels = []
      let nonDiscountedHotels = []
      if(this.hotels) {
        for (let i = 0; i < this.hotels.length; i++) {
          const hotel = new Hotel(this.hotels[i], isContracted, this.cusRoomcashAmount)
          if (!hotel.id || hotel.rooms.length === 0) continue

          let checkIn = isContracted ?
            moment(this.hotels[i].check_in_date) :
            moment(this.$store.state.hotel.checkInDate)
          let checkOut = isContracted ?
            moment(this.hotels[i].check_out_date) :
            moment(this.$store.state.hotel.checkOutDate)

          if (isContracted) {
            this.setContractedHotels(hotel)
            await hotel.updateHotelInfo()
          }

          hotel.checkIn = checkIn._d
          hotel.checkOut = checkOut._d

          let yesterday = moment().subtract(1, 'days')._d
          if ((checkIn >= yesterday ) && (checkOut >= yesterday)) {
            hotel.stayDuration = moment(checkOut).diff(checkIn, 'days')
          } else {
            hotel.tbdDates = true
          }

          if ((checkIn < yesterday) && (checkOut < yesterday) && !hotel.stayDuration) continue
          if(hotel.rewardPoints > 0){
            discountedHotels.push(hotel)
          } else {
            nonDiscountedHotels.push(hotel)
          }
        }
        hotelsArray = discountedHotels.concat(nonDiscountedHotels)
      }
      this.settotalRetailHotels(hotelsArray)
      this.updateHotelsArray(hotelsArray)
      if (!this.orderItem || (this.orderItem.hotel_reservations && this.orderItem.hotel_reservations.length < 1)) {
        this.setLoading(false)
      }
    }
  }
}
</script>
