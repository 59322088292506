<template>
  <div class="retail-hotel-index-container">
    <transition
      name="fade"
    >
      <div
        v-if="$store.state.hotel.retailLoading"
        class="retail-hotels-load"
      />
    </transition>
    <div
      v-if="order.event_hotel_behavior != 'contracted_only'"
      class="retail-hotel-search"
    >
      <HotelSearch
        :eventid="eventid"
      />
    </div>
    <div class="retail-hotel-index">
      <div class="retail-hotel-filter-container columns column">
        <HotelFilter />
      </div>
      <div class="retail-hotel-list">
        <div
          class="alert-container"
          :class="[ ( $store.state.hotel.hasFilterResults == false) ? 'no-results' : '']"
        >
          <div class="alert-icon-container">
            <i class="fa fa-exclamation" />
          </div>
          <div class="alert-message-container">
            <div class="alert-message">
              <span>Your filter options are not showing a match.</span>
            </div>
            <div class="suggestion">
              <span>Try adjusting your filters or see more choices below.</span>
            </div>
          </div>
        </div>
        <IndexView />
        <div
          v-if="getFilteredHotels.length == 0"
          class="default-retail-list"
          :class="[ $store.state.hotel.showFilter == true ? 'filter-show' : '']"
        >
          <div class="overlay" />
          <HotelCard
            v-for="hotel in getHotels.slice(0, pageCount)"
            :key="hotel.index"
            :hotel="hotel"
            :rate="rate"
            :currency="currency"
          />
        </div>
        <div
          v-else
          class="filtered-retail-list"
          :class="[ $store.state.hotel.showFilter == true ? 'filter-show' : '']"
        >
          <div class="overlay" />
          <HotelCard
            v-for="hotel in getFilteredHotels.slice(0, pageCount)"
            :key="hotel.index"
            :hotel="hotel"
            :rate="rate"
            :currency="currency"
          />
        </div>
        <div class="more-hotels-container">
          <div
            v-if="displayShowMore && isXGames()"
            class="btn btn-inverted more-retail-hotels-btn"
            @click="showMoreHotels()"
          >
            <span class="btn-text">{{ $t('h4h.showMore') }}</span>
            <div class="arrow" />
          </div>
          <div
            v-else-if="displayShowMore"
            class="btn btn-inverted more-retail-hotels-btn"
            @click="showMoreHotels()"
          >
            {{ $t('h4h.showMore') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './hotel_availability'
import moment from 'moment'
import HotelFilter from './retail_hotel_filter'
import HotelSearch from './hotel_search'
import IndexView from './index_view'
import HotelCard from './hotel_card'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    HotelFilter,
    HotelSearch,
    IndexView,
    HotelCard
  },

  mixins: [mixin],

  props:{
    rate: {
      default: 1,
      type: Number
    },
    currency: {
      default: 'USD',
      type: String
    },
    eventid: {
      default: 'USD',
      type: String
    }
  },
  data() {
    return {
      latitude: null,
      longitude: null,
      cusRoomcashAmount:0,
      destination: null
    }
  },

  computed: {
    ...mapState('hotel', {
      pageCount: state => state.pageCount
    }),
    ...mapGetters('hotel', [
      'getRetailHotels',
      'getHotels',
      'getFilteredHotels',
      'getAllHotels'
    ]),
    order(){
      return this.$store.state.order.order
    },
    searchDestination(){
      return document.getElementById('searchParams') && document.getElementById('searchParams').dataset.destination
    },
    displayShowMore() {
      if (this.getFilteredHotels.length !== 0) {
        return this.pageCount < this.getFilteredHotels.length
      } else {
        return this.pageCount < this.getRetailHotels.length
      }
    }
  },

  async mounted() {
    this.setRetailLoading(true)
    await this.fetchGeocode()
  },

  methods:{
    ...mapMutations('hotel', ['increasePageCount']),
    ...mapMutations('event', ['updateArnSiteId']),
    showMoreHotels(){
      this.increasePageCount(10)
    },

    async fetchGeocode() {
      // this.latitude = this.$store.state.hotel.eventLatitude
      // this.longitude = this.$store.state.hotel.eventLongitude
      this.destination = this.$store.state.hotel.destination
      if(this.latitude && this.longitude && this.destination) {
        this.fetchAvailableHotels(
          this.latitude,
          this.longitude,
          moment(this.$store.state.hotel.checkInDate).format('YYYY-MM-DD'),
          moment(this.$store.state.hotel.checkOutDate).format('YYYY-MM-DD'),
          this.$store.state.hotel.roomQty,
          this.eventid,
          this.objectifiedExtraSearchParams()
        )
      } else {
        this.fetchAvailableHotelsByDestination(
          this.destination,
          moment(this.$store.state.hotel.checkInDate).format('YYYY-MM-DD'),
          moment(this.$store.state.hotel.checkOutDate).format('YYYY-MM-DD'),
          this.$store.state.hotel.roomQty,
          this.eventid,
          this.objectifiedExtraSearchParams()
        )
      }
    },
    isXGames() {
      return (window.name == 'x-games')
    }
  }
}
</script>
