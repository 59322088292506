<template>
  <div class="room-quantity">
    <label>{{ $t('h4h.roomNum') }}</label>
    <div class="counter">
      <span
        id="subtractRoom"
        :class="[disableSubtraction ? 'disable' : '', 'subtract']"
        @click="updateQuantity(-1, 'subtract')"
      />
      <span
        id="numberOfRooms"
        class="number-of-rooms"
      >
        {{ quantity }}
      </span>
      <span
        id="addRoom"
        :class="[disableAddition() ? 'disable' : '', 'add']"
        @click="updateQuantity(1, 'add')"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    contracted: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    quantity() {
      return this.$store.state.hotel.roomQty
    },
    disableSubtraction() {
      return this.quantity <= this.order.minimum_hotels_required
    },
    order() {
      return this.$store.state.order.order
    }
  },

  created(){
    this.setRoomQty()
  },

  methods: {
    ...mapMutations('hotel', ['updateRoomQty','increaseRoomQty']),

    setRoomQty(){
      this.updateRoomQty(Math.max(this.order.minimum_hotels_required, 1))
    },

    packageQty() {
      let roomInventories = this.$store.state.hotel.hotel.rooms.map ( room => {
        return room.inventory
      })
      return Math.min(...roomInventories, 10)
    },
    disableAddition() {

      if (this.contracted) {
        return this.quantity >= this.packageQty()
      } else {
        return false
      }
    },
    updateQuantity(value, operation) {
      if ( operation =='add' && this.quantity == this.packageQty()-1 ) {
        document.getElementById('addRoom').classList.add('disable')
      }

      if ( operation =='subtract' ) {
        if (document.getElementById('addRoom').classList.contains('disable')){
          document.getElementById('addRoom').classList.remove('disable')
        }
      }

      switch (operation) {
      case 'add':
        if (!this.disableAddition()) {
          this.increaseRoomQty(value)
        }
        break

      case 'subtract':
        if (!this.disableSubtraction) {
          this.increaseRoomQty(value)
        }
        break
      }
    }
  }
}
</script>
