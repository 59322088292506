<template>
  <div class="hotel-search-vue-form">
    <!-- Mobile search form -->
    <div
      id="mobileSearchButton"
      class="mobile-search-button"
      @click="showSearchForm"
    >
      <div class="mobile-search-icon">
        <i class="fa fa-search mobile-hotel-search-icon" />
      </div>
      <div class="mobile-search-info">
        <span class="mobile-info-search-location">{{ destination }}</span>
        <div class="mobile-dates-room-info">
          <span class="mobile-hotel-dates">
            {{ getDates() }}
          </span>
          <br>
          <span class="mobile-hotel-rooms">
            {{ roomQuantity }}
            {{ roomQuantity > 1 ? $t('h4h.rooms') : $t('h4h.room') }}
          </span>
        </div>
      </div>
    </div>
    <!-- Desktop search form -->
    <VForm
      :class="['hotel-search-form', displaySearchForm]"
      @submit="validateFields"
    >
      <!-- Destination search box -->
      <div class="destination">
        <label
          for="hotel_search_destination"
          class="destination-label"
        >
          {{ $t('h4h.destination') }}
          <i
            class="fa fa-times"
            @click="exitSearchForm($event)"
          />
        </label>
        <div class="destination-and-icon-container">
          <i class="fa fa-map-marker" />
          <Field
            id="hotel_search_destination"
            v-model="destination"
            rules="required"
            class="form-control destination-input"
            type="text"
            :placeholder="$t(&quot;h4h.destinationPlaceholder&quot;)"
            name="destination"
            :disabled="isLocationLocked"
            @input="searchLocations"
          />
          <div
            v-if="locationSuggestions.length > 0"
            class="location-suggestions"
          >
            <ul class="location-suggestions-ul">
              <li
                v-for="(place, index) in locationSuggestions"
                :key="index"
                class="location-suggestion"
                @click="selectSuggestedLocation(place)"
              >
                {{ place.text }}
              </li>
            </ul>
          </div>
          <ErrorMessage
            as="label"
            name="destination"
            class="error help is-danger is-italic"
          />
        </div>
      </div>
      <!-- check in and out box -->
      <div class="stay-dates">
        <label
          for="hotel_search_datepicker"
          class="date_label"
        >
          {{ $t('h4h.checkIn') }} - {{ $t('h4h.checkOut') }}
        </label>
        <!-- eslint-disable -->
        <DatePicker
          id="hotel_search_datepicker"
          v-model:value="selectedDates"
          :disabled-date="notBeforeToday"
          name="dates"
          placeholder="Select date range"
          range
          @change="validateDate"
        />
        <!-- eslint-enable -->
        <label
          id="hotel_form_search_dates_error"
          as="label"
          name="dates"
          class="dates_error error help is-danger is-italic"
        />
      </div>
      <!-- Room quantity and update search button -->
      <div class="mobile-hotel-date-holder">
        <div class="room-quantity-parent">
          <div class="room-quantity">
            <label class="room-quantity-label">
              {{ $t('h4h.roomNum') }}
            </label>
            <div class="counter">
              <span
                :class="[disableSubtraction ? 'disable' : '', 'subtract']"
                @click="updateRoomQuantity(-1, 'subtract')"
              />
              <span class="number-of-rooms">
                {{ roomQuantity }}
              </span>
              <span
                :class="[disableAddition ? 'disable' : '', 'add']"
                @click="updateRoomQuantity(1, 'add')"
              />
            </div>
          </div>
        </div>
        <button
          v-if="isXGames()"
          class="btn btn-secondary update-search"
          type="submit"
        >
          <span class="btn-text">{{ $t('h4h.updateSearch') }}</span>
          <div class="arrow" />
        </button>

        <button
          v-else
          class="btn btn-secondary update-search"
          type="submit"
        >
          {{ $t('h4h.updateSearch') }}
        </button>
      </div>
    </VForm>
  </div>
</template>

<script>
/* global locale */
import DatePicker from 'vue-datepicker-next'
import mixin from './hotel_availability'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { Field, Form as VForm, ErrorMessage } from 'vee-validate'
import moment from 'moment'
import cartApi from '../../../shared/cart/cart_api'

export default {
  components: {
    Field,
    VForm,
    ErrorMessage,
    DatePicker
  },

  mixins: [mixin],

  props: {
    eventid: {
      default: 'USD',
      type: String
    }
  },

  data() {
    return {
      destination: null,
      hideSearchClass: true,
      latitude: null,
      longitude: null,
      cusRoomcashAmount:0,
      selectedDates: [],
      eventDates: [],
      roomQuantity: 1,
      pickerPosition: { left: 0 },
      locationSuggestions: []
    }
  },

  computed: {
    ...mapState({
      hotel: state => state.hotel
    }),
    ...mapGetters('hotel', ['sendCheckInToArn', 'sendCheckOutToArn']),
    disableSubtraction() {
      return this.roomQuantity <= this.hotel.roomQty
    },
    disableAddition() {
      return this.roomQuantity >= 10
    },
    standardH4HForm(){
      return !this.order.blank_h4h_form
    },
    order(){
      return this.$store.state.order.order
    },
    isLocationLocked() {
      return this.order.event_h4h_post_purchase_enabled ? false : this.order.event_hotel_search_locked_location
    },
    displaySearchForm(){
      return this.hideSearchClass ? 'mobile-hotel-search-hidden' : ''
    },
    searchParams(){
      return document.getElementById('searchParams')
    },
    propertyTypes(){
      if(this.searchParams){
        return document.getElementById('searchParams').dataset.propertytypes
      } else {
        return null
      }
    },
    propertyAmenities(){
      if(this.searchParams && this.searchParams.dataset.propertyamenities){
        let amenitiesArray = document.getElementById('searchParams').dataset.propertyamenities.split(',')
        return amenitiesArray.map(amenity => this.amenityValueMap[amenity])
      } else {
        return null
      }
    },
    propertyClasses(){
      if(this.searchParams){
        return document.getElementById('searchParams').dataset.propertyclasses
      } else {
        return null
      }
    },
  },

  async mounted() {
    // Setup destination
    if(this.standardH4HForm){
      this.destination = this.hotel.h4h_destination || this.hotel.destination
      if(!this.destination){
        await this.getBackendOrder()
        this.destination = this.hotel.h4h_destination || this.hotel.destination
        this.submitForm()
      }
    } else if (document.getElementById('searchParams')){

      this.setSearchValues()
    }
    this.latitude = this.hotel.h4hLatitude || this.hotel.searchLatitude
    this.longitude = this.hotel.h4hLongitude || this.hotel.searchLongitude
    // this.enableAutocomplete()
    this.enableClickAway()

    // Setup date picker
    await this.calendarLocale()
    if(this.standardH4HForm){
      this.selectedDates.push(
        moment(this.hotel.checkInDate)._d,
        moment(this.hotel.checkOutDate)._d
      )
    }

    let el = document.getElementsByClassName('mx-input')
    el[0].setAttribute('readonly', true)

    // Setup room quantity
    if(this.order.minimum_hotels_required > 1){
      this.hotel.roomQty = this.order.minimum_hotels_required
    }
    this.roomQuantity = this.hotel.roomQty

    //testing to see if below works
    if (document.getElementById('searchParams')){
      //this.submitForm()
      this.validateFields()
    }
  },

  methods: {
    ...mapMutations('hotel', [
      'setRetailLoading',
      'updateDestination',
      'updateSearchLat',
      'updateSearchLng',
      'updateCheckInDate',
      'updateCheckOutDate',
      'updateRoomQty',
      'increaseRoomQty'
    ]),
    ...mapActions('order', ['getBackendOrder']),
    setSearchValues(){
      const searchParams = document.getElementById('searchParams').dataset
      this.destination = searchParams.destination
      this.hotel.roomQty = parseInt(searchParams.rooms)
      this.eventDates =[searchParams.checkin, searchParams.checkout]
      this.selectedDates =[new Date(searchParams.checkin + 'T00:00:00.000'), new Date(searchParams.checkout + 'T00:00:00.000')]
    },
    showSearchForm() {
      document.body.classList.toggle('mobile-search-form')
      let searchButton = document.getElementById('mobileSearchButton')
      this.hideSearchClass = false
      searchButton.style.display='none'
    },

    searchLocations() {
      const input = this.destination.trim()
      if( input === '' || input.length < 3){
        this.locationSuggestions = []
        return
      } else {
        cartApi.getLocationSuggestions(this.destination).then(response => {
          this.locationSuggestions = response
        })
      }
    },
    enableClickAway(){
      document.addEventListener('click', (event) => {
        if(this.locationSuggestions.length <= 0){ return }

        const isClickInside = event.target.closest('.location-suggestions')

        // If the click is outside the locationSuggestionsElement, perform your logic
        if (!isClickInside) {
          this.locationSuggestions = []
        }
      })
    },
    getDates(){
      moment.locale(locale)
      if(this.standardH4HForm) {
        let checkin = moment(this.$store.state.hotel.checkInDate).format('L')
        let checkout = moment(this.$store.state.hotel.checkOutDate).format('L')
        return `${checkin} - ${checkout}`
      } else {
        return 'Select Location and Dates'
      }
    },
    exitSearchForm(e) {
      e.preventDefault()
      document.body.classList.toggle('mobile-search-form')
      let searchButton = document.getElementById('mobileSearchButton')
      this.hideSearchClass = true
      searchButton.style.display = 'flex'
    },
    selectSuggestedLocation(place) {
      this.destination = place.text
      this.locationSuggestions = []
    },
    notBeforeToday(date) {
      return date < moment().subtract(1, 'days')
    },
    displayEventDots() {
      let calElement = document
        .getElementsByClassName('mx-calendar mx-calendar-panel-date')[1]
      calElement.style.display = 'none'
      if (this.eventDates.length < 1) {
        let checkin = moment(this.$store.state.hotel.checkInDate)._d
        let checkout = moment(this.$store.state.hotel.checkOutDate)._d
        while(checkin <= checkout) {
          this.eventDates.push(moment(checkin).format('YYYY-MM-DD'))
          checkin = moment(checkin).add(1, 'days')._d
        }
      }

      this.eventDates.forEach(date => {
        window.setTimeout(() => {}, 100)
        let el = document.querySelector('[title="'+ date +'"]')
        let eventDot = document.createElement('div')
        eventDot.setAttribute('class', 'event-dot')
        el.insertBefore(eventDot, el.firstElementChild.previousSibling)
      })
    },
    hideEventDots() {
      this.eventDates.forEach(date => {
        let el = null
        this.$nextTick(() => {
          el = document.querySelector('[title="'+ date +'"]')
          let dots = Array.from(document.getElementsByClassName('event-dot'))
          if (el && (el.className !== 'cell not-current-month')) {
            dots.forEach(dot => {
              dot.style.display = 'block'
            })
          } else {
            dots.forEach(dot => {
              dot.style.display = 'none'
            })
          }
        })
      })
    },
    changePosition() {
      this.pickerPosition = {
        left: `${document.
          getElementById('hotel_search_datepicker').
          getBoundingClientRect().left}px`
      }
    },
    async calendarLocale() {
      switch (locale) {
      case 'es-MX':
        await import('vue-datepicker-next/locale/es')
        break
      case 'de':
        await import('vue-datepicker-next/locale/de')
        break
      case 'it':
        await import('vue-datepicker-next/locale/it')
        break
      case 'fr':
        await import('vue-datepicker-next/locale/fr')
        break
      case 'ja':
        await import('vue-datepicker-next/locale/ja')
        break
      case 'zh-CN':
        await import('vue-datepicker-next/locale/zh-cn')
        break
      case 'pl':
        await import('vue-datepicker-next/locale/pl')
        break
      default:
        break
      }
    },
    updateRoomQuantity(value, operation) {
      switch(operation) {
      case 'add':
        if (!this.disableAddition) {
          this.roomQuantity += 1
        }
        break

      case 'subtract':
        if (!this.disableSubtraction) {
          this.roomQuantity -= 1
        }
        break
      }
    },

    areDatesNull() {
      if(this.selectedDates.length > 0) {
        return this.selectedDates.every(el => el === null)
      } else {
        return true
      }
    },

    async validateFields() {
      if (this.areDatesNull()) {
        let errorblock = document.getElementById('hotel_form_search_dates_error')
        errorblock.innerHTML = 'This field is required'
        errorblock.style.display = 'block'
      } else {
        this.selectedDates.map(valid => {
          if(valid && !this.areDatesNull()) {
            let errorblock = document.getElementById('hotel_form_search_dates_error')
            errorblock.style.display = 'none'
            this.submitForm()
          }
        })
      }
    },

    validateDate(value) {
      if(value === null) {
        return 'Please enter a valid date'
      } else {
        return true
      }
    },


    async submitForm() {
      this.setRetailLoading(true)
      this.updateDestination(this.destination)
      if(this.selectedDates[0]) {
        this.updateCheckInDate(this.selectedDates[0])
      }
      if(this.selectedDates[1]){
        this.updateCheckOutDate(this.selectedDates[1])
      }
      this.updateRoomQty(this.roomQuantity)
      await this.fetchGeocode()
    },
    async fetchGeocode() {
      if (this.latitude && this.longitude && !this.h4h_destination) {
        this.updateSearchLat(this.latitude)
        this.updateSearchLng(this.longitude)
        this.fetchAvailableHotels(
          this.latitude,
          this.longitude,
          this.reformatCheckIn(),
          this.reformatCheckOut(),
          this.roomQuantity,
          this.eventid,
          this.objectifiedExtraSearchParams()
        )
        this.latitude = null
        this.longitude = null
      } else {
        this.fetchAvailableHotelsByDestination(
          this.destination,
          this.reformatCheckIn(),
          this.reformatCheckOut(),
          this.roomQuantity,
          this.eventid,
          this.objectifiedExtraSearchParams()
        )
      }
    },
    reformatCheckIn(){
      return moment(this.$store.state.hotel.checkInDate).format('YYYY-MM-DD')
    },
    reformatCheckOut(){
      return moment(this.$store.state.hotel.checkOutDate).format('YYYY-MM-DD')
    },
    isXGames() {
      return (window.name == 'x-games')
    }
  }
}
</script>
