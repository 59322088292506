
import { trackPageView as gtmTrackPageView } from '../../../default/google_tag_manager'

export function trackHotelOverview (hotel) {
  gtmTrackPageView(`${window.location.pathname}#${hotel.id}`, 'Hotel Overview', `${window.location.pathname}#${hotel.id}`)
}

export function trackHotelIndex(){
  gtmTrackPageView(window.location.pathname, 'Hotel Index', window.location.pathname)
}

export function getStayPrice(hotel) {
  return hotel.rooms.find(room => room.inventory > 0 && room.active).total
}
