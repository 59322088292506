<template>
  <div class="hotel-image-slider">
    <div
      class="previous-btn"
      @click="previous"
    />
    <div
      class="next-btn"
      @click="next"
    />
    <div
      v-if="!images"
      class="images-loader"
    />
    <img
      v-for="(image, index) in images"
      ref="images"
      :key="index"
      :class="{ 'showing': index === 0 }"
      :src="image.ImagePath"
    >
  </div>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    images: Array,
    hotelid: {
      default: null,
      type: Number
    }
  },

  data() {
    return {
      counter: 0,
      prevCounterGreater: null,
      reverse: false
    }
  },

  computed: {
    maxCount() {
      return this.images.length
    }
  },

  watch: {
    counter() {
      for (let i = 0; i < this.$refs.images.length; i++) {
        let img = this.$refs.images[i]
        img.className = ''
      }
      let incomingSlide = this.$refs.images[this.counter]
      incomingSlide.className = 'showing'
      if( this.reverse ){
        this.reverseGallery(incomingSlide)
      } else {
        if (0 <= this.counter < this.maxCount) {
          let currentSlide = this.prevCounterGreater ?
            this.$refs.images[this.counter + 1] : this.$refs.images[this.counter - 1]
          currentSlide.className = 'last-slide'
        }
        const end = this.prevCounterGreater ? 'translateX(-300px)' : 'translateX(300px)'
        incomingSlide.animate([
          {transform: 'translateX(0px)'},
          {transform: end}
        ], {
          duration: 200,
          direction: 'reverse'
        })}
    }
  },

  methods: {
    next() {
      if(this.counter < this.maxCount -1 ) {
        this.prevCounterGreater = false
        this.counter++
      } else {
        this.prevCounterGreater = true
        this.counter = this.$refs[this.hotelid].length - this.$refs[this.hotelid].length
        this.reverse = true
      }
    },

    previous() {
      if(this.counter > 0) {
        this.prevCounterGreater = true
        this.counter--
      } else{
        this.prevCounterGreater = false
        this.counter = this.$refs[this.hotelid].length -1
        this.reverse = true
      }
    },
    reverseGallery(incomingSlide) {
      if (0 <= this.counter < this.maxCount) {
        let currentSlide = this.$refs[this.hotelid][0]
        currentSlide.className = 'last-slide'
      }
      const end = this.prevCounterGreater ? 'translateX(300px)' : 'translateX(-300px)'
      incomingSlide.animate([
        {transform: 'translateX(0px)'},
        {transform: end}
      ], {
        duration: 200,
        direction: 'reverse'
      })
      this.reverse = false
    }
  }
}
</script>
