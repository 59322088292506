<template>
  <div class="hotel-icon-container">
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 295.1 266.1"
      style="enable-background:new 0 0 295.1 266.1;"
      xml:space="preserve"
      class="hotel-icon"
    >
      <g>
        <polygon
          class="st0"
          points="147.6,59.4 147.6,85.4 265.2,85.4 265.2,240.3 147.6,240.3 147.6,266.1 295.1,266.1 295.1,59.4  "
        />
        <rect
          x="206.4"
          y="119.6"
          class="st0"
          width="29.9"
          height="29.9"
        />
        <rect
          x="206.4"
          y="178.1"
          class="st0"
          width="29.9"
          height="29.9"
        />
        <rect
          x="147.6"
          y="119.6"
          class="st0"
          width="29.9"
          height="29.9"
        />
        <rect
          x="147.6"
          y="178.1"
          class="st0"
          width="29.9"
          height="29.9"
        />
      </g>
      <path
        class="st0"
        d="M0,0v266.3h147.6V0H0z M59.3,237.2H29.4v-29.9h29.9V237.2z M59.3,178.7H29.4v-29.9h29.9V178.7z M59.3,119.6  H29.4v-30h29.9V119.6z M59.3,61H29.4V31.1h29.9V61z M118.1,237.2H88.2v-29.9h29.9V237.2z M118.1,178.7H88.2v-29.9h29.9V178.7z   M118.1,119.6H88.2v-30h29.9V119.6z M118.1,61H88.2V31.1h29.9V61z"
      />
    </svg>
  </div>
</template>
