export default class Room {
  constructor(json, contracted = false, ratePlan=null, gateway=null, cusRoomcashAmount) {
    // Contracted
    if (contracted) {
      this.name = json.room_type
      this.description = json.description
      this.stayId = json.id
      this.total = json.price
      this.inventory = json.inventory_on_site
      this.active = json.active
    } else {
      // Retail
      this.gateway = gateway
      this.rewardPoints =  this.calulateRewardPoints( json['@Discount'], cusRoomcashAmount)
      this.nightlyRate =  this.combineNightlyRates(json['NightlyRate'])
      this.name = json['@Name']
      this.description = json['@Description']
      this.stayId = null
      this.total = json.Total['@Amount']
      this.roomCode = json['@Code']
      this.gatewayFee = json['GatewayFee']['@Amount']
      this.taxAmount = json['Tax']['@Amount']
      this.bookingFee = json['BookingFee']['@Amount']
      this.currencyCode = json['@CurrencyCode']
      this.ratePlan = ratePlan
      this.active = true
    }
  }

  calulateRewardPoints( discount_amount, cusRoomcashAmount) {
    if(discount_amount){
      return Math.min(cusRoomcashAmount, 50, Math.ceil(discount_amount))
    } else {
      return 0
    }
  }

  combineNightlyRates(nightlyRate){
    if (Array.isArray(nightlyRate)) {
      let totalPrice = 0
      nightlyRate.forEach(rateObj => {
        totalPrice += parseFloat(rateObj['@Price'])
      })
      return totalPrice.toFixed(2)
    } else {
      return nightlyRate['@Price']
    }
  }
}
