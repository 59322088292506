<template>
  <div class="retail-hotel-info">
    <div class="retail-hotel-details">
      <div class="retail-hotel-title">
        <h4>{{ hotel.name }}</h4>
      </div>
      <p>{{ hotel.fullAddress }}</p>
      <p v-if="hotel.directions && standardH4HForm">
        {{ hotel.directions }} to {{ $store.state.hotel.venue }}
      </p>
      <div
        v-if="hotel.reviews!=0"
        class="retail-hotel-rating"
      >
        <p class="rate">
          {{ hotel.rating ? hotel.rating.toFixed(1) : '0.0' }}
        </p>
        <p class="review-count small">
          {{ hotel.reviews }} {{ $t('h4h.userReviews') }}
        </p>
      </div>
    </div>
    <div class="retail-hotel-purchasing">
      <div class="retail-hotel-pricing">
        <span class="price">
          <CurrencyConvert
            v-if="hasStayDuration"
            :amount="(price / hotel.stayDuration)*rate"
            :usd="(price / hotel.stayDuration).toString()"
            :currency="currency"
          />
          <CurrencyConvert
            v-else
            :amount="(price)*rate"
            :usd="(price).toString()"
            :currency="currency"
          />
        </span>
        <span
          v-if="hasStayDuration"
          class="per-night"
        >{{ $t('h4h.perNight') }}</span>
        <span
          v-if="hasStayDuration && checkIn != 'Invalid date' && checkOut != 'Invalid date'"
          class="total-price"
        >
          <CurrencyConvert
            :amount="price*rate"
            :usd="price"
            :currency="currency"
          />
          {{ $t('h4h.contractedTotal', {
            checkIn,
            checkOut
          }) }}
        </span>
        <span
          v-else-if="hasStayDuration"
          class="total-price"
        >
          <CurrencyConvert
            :amount="price*rate"
            :usd="price"
            :currency="currency"
          />
          {{ $t('h4h.roomTotal', {quantity: hotel.stayDuration}) }}
        </span>
        <span
          v-else
          class="total-price"
        >
          <CurrencyConvert
            :amount="price*rate"
            :usd="price"
            :currency="currency"
          />
          {{ $t('h4h.tbd') }}
        </span>
      </div>
      <div class="retail-hotel-show-btn">
        <div
          v-if="isXGames()"
          class="btn btn-secondary room-select-btn"
          :data-hotel-name="hotel.name"
          @click="selectSpecifiedHotel(hotel)"
        >
          <span class="btn-text">{{ $t('h4h.chooseRoom') }}</span>
          <div class="arrow" />
        </div>

        <div
          v-else
          class="btn btn-secondary room-select-btn"
          :data-hotel-name="hotel.name"
          @click="selectSpecifiedHotel(hotel)"
        >
          {{ $t('h4h.chooseRoom') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { CurrencyConvert } from '../../../default/currency_convert'
import {trackHotelIndex, trackHotelOverview, getStayPrice} from './shared_hotel_card_functions'

import moment from 'moment'
/* global locale */
export default {
  components: {
    CurrencyConvert,
  },
  props:{
    hotel: {
      default: () => {},
      type: Object,
    },
    rate: {
      default: 0,
      type: Number
    },
    currency: {
      default: 'USD',
      type: String
    }
  },
  computed: {
    ...mapGetters('hotel', ['getLowestValue', 'getTBD']),
    price() {
      if(this.hotel.contracted){
        return getStayPrice(this.hotel)
      } else {
        return this.getLowestValue(this.hotel.id)
      }
    },
    hasStayDuration(){
      return this.hotel.stayDuration > 0
    },
    standardH4HForm(){
      return !this.order.blank_h4h_form
    },
    order(){
      return this.$store.state.order.order
    },
    checkIn(){
      moment.locale(locale)
      if (this.isAusOpen) {
        return moment(this.hotel.checkIn).format('LL')
      } else {
        return moment(this.hotel.checkIn).format('YYYY-MM-DD')
      }
    },
    checkOut(){
      moment.locale(locale)
      if(this.isAusOpen) {
        return moment(this.hotel.checkOut).format('LL')
      } else {
        return moment(this.hotel.checkOut).format('YYYY-MM-DD')
      }

    },
    isAusOpen() {
      return (window.brand_name == 'The International')
    },
    numberOfNights(){
      if(this.hasStayDuration) {
        return `for ${this.hotel.stayDuration} nights`
      } else {
        return 'total'
      }
    },
  },
  methods:{
    ...mapMutations('hotel', ['selectHotel', 'deselectHotel', 'setRetailHotelStatus']),

    selectSpecifiedHotel(hotel){
      this.selectHotel(hotel)
      this.setRetailHotelStatus(true)
      this.manipulateHistory(hotel)

      trackHotelOverview(hotel)
    },
    manipulateHistory(hotel){
      window.history.pushState(hotel.id, null, `hotels#${hotel.id}`)
      window.addEventListener('popstate', (e)=>{
        if(e.state !== null) {
          this.selectHotel(hotel)
          trackHotelOverview(hotel)
        } else {
          this.selectHotel(null)
          trackHotelIndex()
        }
      })
    },
    isXGames() {
      return (window.name == 'x-games')
    }
  }
}
</script>
