<template>
  <div
    v-if="(room.ratePlan || room.inventory > 0) && room.active"
    class="roomcash retail-room-card-container"
  >
    <div class="retail-room-details">
      <div class="retail-room-title">
        <h3>{{ room.name }}</h3>
      </div>
      <div
        v-if="hasExclusiveRates"
        class="exclusive-rates-badge"
      >
        {{ $t('h4h.exclusiveRates') }}
      </div>
      <p
        v-if="hotel.contracted && notEnoughRooms"
        class="contracted-rooms-remaining"
      >
        {{ room.inventory == 1 ? $t('h4h.roomRemaining', {
          quantity: room.inventory
        }) : $t('h4h.roomsRemaining', {
          quantity: room.inventory
        }) }}
      </p>
      <p class="room-description mobile">
        {{ room.description }}
      </p>
      <div class="retail-room-pricing desktop">
        <span
          v-if="room.rewardPoints > 0"
          class="price"
        >
          <div class="save-amt-text-wrapper">
            <span class="save-amt-text">{{ $t('h4h.saveAmtRoomcash', {amt: formatRewardPointsPerNight }) }}</span>
          </div>
          <span class="tooltip-wrapper">
            <span>
              <b class="roomcash-amount-tooltip">i
                <span>{{ $t('h4h.roomcashTooltipText') }}</span>
              </b>
            </span>
          </span>
          <span class="striked-off-price">
            <CurrencyConvert
              :amount="((room.total / stayDuration) + rewardPointsPerNight) * rate"
              :usd="(room.total / stayDuration) + rewardPointsPerNight"
              :currency="currency"
            />
          </span>
          <b>
            <CurrencyConvert
              :amount="((room.total / stayDuration)) * rate"
              :usd="((room.total / stayDuration))"
              :currency="currency"
            />
          </b>
        </span>
        <span
          v-else
          class="price"
        >
          <b>
            <CurrencyConvert
              :amount="(room.total / stayDuration) * rate"
              :usd="(room.total / stayDuration)"
              :currency="currency"
            />
          </b>
        </span>
        <span class="total-price">
          <CurrencyConvert
            :amount="(room.total) * rate"
            :usd="(room.total)"
            :currency="currency"
          />
          <span class="hotel-show-stay-length">
            {{ (getRetailHotelStatus || !getTBDStatus) ? " " + $t('h4h.roomTotal', {
              quantity: stayDuration
            }) : $t('h4h.tbd') }}
          </span>
        </span>
        <p
          v-if="hotelPriceChange"
          class="hotel-price-change"
        >
          {{ $t('h4h.priceChange') }}
        </p>
        <p
          v-if="hotelNotFound"
          class="hotel-price-change"
        >
          {{ $t('checkout.payment.hotelNotAvailable') }}
        </p>
      </div>
    </div>
    <div
      v-if="room.rewardPoints>0"
    >
      <RoomcashScaleContainer
        :reward-points="room.rewardPoints"
        :currency="currency"
        :rate="rate"
        :price="room.total"
      />
    </div>
    <div class="retail-room-pricing mobile">
      <span
        v-if="room.rewardPoints > 0"
        class="price"
      >
        <div class="save-amt-text-wrapper">
          <span class="save-amt-text">{{ $t('h4h.saveAmtRoomcash', {amt: formatRewardPointsPerNight }) }}</span>
        </div>
        <span class="tooltip-wrapper">
          <span>
            <b class="roomcash-amount-tooltip">i
              <span>{{ $t('h4h.roomcashTooltipText') }}</span>
            </b>
          </span>
        </span>
        <span class="striked-off-price">
          <CurrencyConvert
            :amount="((room.total / stayDuration) + rewardPointsPerNight) * rate"
            :usd="(room.total / stayDuration) + rewardPointsPerNight"
            :currency="currency"
          />
        </span>
        <b>
          <CurrencyConvert
            :amount="((room.total / stayDuration)) * rate"
            :usd="((room.total / stayDuration))"
            :currency="currency"
          />
        </b>
      </span>
      <span
        v-else
        class="price"
      >
        <b>
          <CurrencyConvert
            :amount="(room.total / stayDuration) * rate"
            :usd="(room.total / stayDuration)"
            :currency="currency"
          />
        </b>
      </span>
      <span class="total-price">
        <CurrencyConvert
          :amount="room.total * rate"
          :usd="room.total"
          :currency="currency"
        />
        <span class="hotel-show-stay-length">
          {{ (getRetailHotelStatus || !getTBDStatus) ? $t('h4h.roomTotal', {
            quantity: stayDuration
          }) : $t('h4h.tbd') }}
        </span>
      </span>
      <p
        v-if="hotelPriceChange"
        class="hotel-price-change"
      >
        {{ $t('h4h.priceChange') }}
      </p>
      <p
        v-if="hotelNotFound"
        class="hotel-price-change"
      >
        {{ $t('checkout.payment.hotelNotAvailable') }}
      </p>
    </div>
    <div class="retail-room-purchasing">
      <p class="room-description desktop">
        {{ room.description }}
      </p>
      <div
        class="btn btn-secondary btn-size"
        :class="disableButton ? 'btn-disabled' : ''"
        :disable="disableButton || disableForSubmission"
        @click="changeOrderItem()"
      >
        {{ $t('h4h.selectRoom') }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../cart/cart_api'
import { CurrencyConvert, CurrencyApi } from '../../../default/currency_convert'
import { mapActions, mapState } from 'vuex'
import { mapMutations } from 'vuex'

import { mapGetters } from 'vuex'
import moment from 'moment'
import RoomcashScaleContainer from './roomcash_scale_container.vue'

export default {
  components: {
    CurrencyConvert,
    RoomcashScaleContainer
  },

  props: {
    checkIn: {
      type: Date,
      default: null
    },
    checkOut: {
      type: Date,
      default: null
    },
    hotelId: {
      type: Number,
      default: null
    },
    room: {
      type: Object,
      default: null
    },
    rewardPoints: {
      type: Number,
      default: 0
    },
    stayDuration: {
      type: Number,
      default: null
    },
    rate: {
      type: Number,
      default: 1
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },

  data() {
    return {
      disableForSubmission: false,
      hotelPriceChange: false,
      hotelNotFound: false
    }
  },

  computed: {
    ...mapGetters('hotel', ['getTBDStatus', 'getRetailHotelStatus']),
    ...mapState('hotel', {
      hotel: state => state.hotel,
      roomQty: state => state.roomQty
    }),
    notEnoughRooms() {
      return this.room.inventory <= this.$store.state.packages.packageQty
    },
    disableButton() {
      let roomInCart = false
      if(this.order.items) {
        roomInCart = this.order.items.find( item =>{
          return item.hotel_reservations.length > 0 && item.hotel_reservations[0].room_code == this.room.roomCode
        })
      }
      return roomInCart || this.disableForSubmission ? true : false
    },
    hasExclusiveRates(){
      const targetGateways = ['16', '20', '33']
      return this.hotel.rooms.some(room => targetGateways.includes(room.gateway))
    },
    order(){
      return this.$store.state.order.order
    },
    rewardPointsPerNight() {
      return (this.room.rewardPoints / this.stayDuration)
    },
    formatRewardPointsPerNight() {
      return CurrencyApi.formatAmountNoCents((this.room.rewardPoints / this.hotel.stayDuration )* this.rate, this.currency)
    },
  },

  created() {
    this.setValues()
  },

  methods: {
    ...mapMutations('cart', [
      'displayCart'
    ]),
    ...mapActions('order', ['getBackendOrder']),

    setValues(){
      let chosenPackage=this.order.items[0]
      this.packageId=chosenPackage.package_id
      this.packageQty=this.order.item_count
      this.orderId=this.order.id
      this.orderItemId=chosenPackage.id
    },

    postRetailBody(amount) {
      return JSON.stringify({
        order_item: {
          city: this.hotel.city,
          contracted: this.hotel.contracted,
          hotel_address: this.hotel.addressOne,
          hotel_address_2: this.hotel.addressTwo,
          hotel_check_in_date: moment(this.checkIn).format('DD/MM/YYYY'),
          hotel_check_out_date:moment(this.checkOut).format('DD/MM/YYYY'),
          hotel_check_in_time: this.hotel.checkInTime,
          roomcash_points_used: this.room.rewardPoints,
          hotel_check_out_time: this.hotel.checkOutTime,
          hotel_city: this.hotel.city,
          hotel_country: this.hotel.country,
          hotel_id: this.hotel.id,
          hotel_latitude: this.hotel.latitude,
          hotel_longitude: this.hotel.longitude,
          hotel_name: this.hotel.name,
          hotel_phone: this.hotel.phone,
          hotel_state: this.hotel.state,
          hotel_total_price: amount,
          hotel_zip: this.hotel.zip,
          id: this.orderItemId,
          package_id: this.packageId,
          quantity: this.packageQty,
          room_quantity: this.$store.state.hotel.roomQty,
          room_code: this.room.roomCode,
          rate_plan_code: this.room.ratePlan,
          tax_amount: this.room.taxAmount,
          gateway: this.room.gateway,
          gateway_fee: this.room.gatewayFee,
          room_cost_price: this.room.nightlyRate,
          currency_code: this.room.currencyCode,
          booking_fee: this.room.bookingFee
        }
      })
    },
    postContractedBody(stayId) {
      return JSON.stringify({
        hotel: {
          hotel_id: this.hotel.id,
          room_quantity: this.$store.state.hotel.roomQty,
          stay_id: stayId
        }
      })
    },
    async changeOrderItem() {
      if (this.disableButton || this.disableForSubmission) { return }
      this.disableForSubmission = true
      this.errs = null
      let res = null

      if (this.hotel.contracted) {
        res = await Api.addContractedToCart(
          this.orderItemId,
          this.postContractedBody(this.room.stayId)
        )
        this.getBackendOrder().then( () => this.displayCart())

      } else {
        res = await Api.addH4HHotelToCart(
          this.orderItemId,
          this.postRetailBody(this.room.total)
        )

        if(res.room_updated) {
          /* eslint-disable vue/no-mutating-props */
          this.room.gatewayFee = res.room['GatewayFee']['@Amount']
          this.room.bookingFee = res.room['BookingFee']['@Amount']
          this.room.taxAmount = res.room['Tax']['@Amount']
          this.room.total = res.room['Total']['@Amount']
          this.room.nightlyRate = res.room['GatewayFee']['@Amount']
          this.hotelPriceChange = true
          setTimeout(() => {this.hotelPriceChange = false}, 10000)
        } else if( res.errors) {
          this.hotelNotFound = true
        }
        this.getBackendOrder().then( () => this.displayCart())
      }

      if (!res.id) {
        this.disableForSubmission = false
        this.errs = res.errors || this.$t('formPackage.error')
      }
    }
  }
}
</script>
