<template>
  <div class="redesign-wrapper">
    <!-- eslint-disable vue/no-v-html -->
    <h2
      class="" 
      v-html="customizeYourPackage"
    />
    <div class="ticket-hotel-wrapper">
      <div class="step">
        <h4 v-html="selectEvent" />
        <div class="content">
          <p v-html="stepOne" />
          <!-- <p>Choose your desired race to see available ticket options.</p> -->
        </div>
      </div>
      <div class="step">
        <h4 v-html="selectTickets" />
        <div class="content">
          <p v-html="stepTwo" />
          <!-- <p>Secure the perfect seat with excellent views of the action.</p> -->
        </div>
      </div>
      <div class="step selected">
        <h4 v-html="$t('h4h.selectHotel')" />
        <div class="content">
          <p>{{ $t('h4h.stepThree') }}</p>
          <!-- <p>Find a comfortable and convenient hotel to relax & recharge.</p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      brandNameF1: 'F1<sup>&reg;</sup> '
    }
  },
  computed: {
    customizeYourPackage() {
      if(window.name == 'f1') {
        const f1TicketHotel = this.brandNameF1 + 'Ticket + Hotel '
        return this.$t('h4h.customizeYourPackage', {brandName: f1TicketHotel})
      } else
        return this.$t('h4h.customizeYourPackage', {brandName: ''})
    },
    selectEvent() {
      if(window.name == 'f1')
        return this.$t('h4h.selectEvent', {brandName: this.brandNameF1})
      else
        return this.$t('h4h.selectEvent', {brandName: ''})
    },
    selectTickets() {
      if(window.name == 'f1')
        return this.$t('h4h.selectTickets', {brandName: this.brandNameF1})
      else
        return this.$t('h4h.selectTickets', {brandName: ''})
    },
    stepOne() {
      if(window.name ==  'f1')
        return this.$t('h4h.stepOne', {race: 'Grand Prix<sup>&trade;</sup> '})
      else
        return this.$t('h4h.stepOne', {race: 'race'})
    },
    stepTwo() {
      if(window.name == 'f1')
        return this.$t('h4h.stepTwo', {brandName: this.brandNameF1})
      else
        return this.$t('h4h.stepTwo', {brandName: ''})
    }
  }
}
</script>
