<template>
  <div class="roomcash retail-hotel-info">
    <div class="retail-hotel-details">
      <div class="hotel-title-address-price">
        <div class="hotel-title-address">
          <div class="retail-hotel-title">
            <h4>{{ hotel.name }}</h4>
          </div>
          <p>{{ hotel.fullAddress }}</p>
        </div>
        <div class="retail-hotel-pricing desktop">
          <div
            v-if="rewardPoints > 0"
            class="save-amt-text-wrapper"
          >
            <span class="save-amt-text">{{ $t('h4h.saveAmtRoomcash', {amt: formatRewardPointsPerNight }) }}</span>
          </div>
          <div v-if="hasStayDuration && (rewardPoints > 0)">
            <span class="tooltip-wrapper">
              <span>
                <b class="roomcash-amount-tooltip">i
                  <span>{{ $t('h4h.roomcashTooltipText') }}</span>
                </b>
              </span>
            </span>
            <span class="striked-off-price">
              <CurrencyConvert
                :amount="((price / hotel.stayDuration)+ rewardPointsPerNight )*rate"
                :usd="(price / hotel.stayDuration +rewardPointsPerNight).toString()"
                :currency="currency"
              />
            </span>
            <span class="price">
              <CurrencyConvert
                :amount="(price / hotel.stayDuration)*rate"
                :usd="(price / hotel.stayDuration).toString()"
                :currency="currency"
              />
            </span>
          </div>
          <div v-else-if="hasStayDuration">
            <span class="price">
              <CurrencyConvert
                :amount="(price / hotel.stayDuration)*rate"
                :usd="(price / hotel.stayDuration).toString()"
                :currency="currency"
              />
            </span>
          </div>
          <div v-else>
            <span class="price">
              <CurrencyConvert
                :amount="(price - rewardPoints)*rate"
                :usd="(price - rewardPoints).toString()"
                :currency="currency"
              />
            </span>
          </div>
          <span
            v-if="hasStayDuration"
            class="per-night"
          >{{ $t('h4h.perNight') }}</span>
          <span
            v-if="hasStayDuration && checkIn != 'Invalid date' && checkOut != 'Invalid date'"
            class="total-price"
          >
            <CurrencyConvert
              :amount="(price)*rate"
              :usd="(price).toString()"
              :currency="currency"
            />
            {{ $t('h4h.tbd') }}
          </span>
          <span
            v-else-if="hasStayDuration && (rewardPoints > 0)"
            class="total-price"
          >
            <CurrencyConvert
              :amount="(price)*rate"
              :usd="(price).toString()"
              :currency="currency"
            />
            {{ $t('h4h.tbd') }}
          </span>
          <span
            v-else
            class="total-price"
          >
            <CurrencyConvert
              :amount="price*rate"
              :usd="price"
              :currency="currency"
            />
            {{ $t('h4h.tbd') }}
          </span>
        </div>
      </div>
      <div
        v-if="rewardPoints>0"
      >
        <RoomcashScaleContainer
          :reward-points="rewardPoints"
          :currency="currency"
          :rate="rate"
          :price="price"
        />
      </div>
    </div>
    <div class="retail-hotel-pricing mobile">
      <div
        v-if="rewardPoints > 0"
        class="save-amt-text-wrapper"
      >
        <span class="save-amt-text">{{ $t('h4h.saveAmtRoomcash', {amt: formatRewardPointsPerNight }) }}</span>
      </div>
      <div v-if="hasStayDuration && (rewardPoints > 0)">
        <span class="tooltip-wrapper">
          <span>
            <b class="roomcash-amount-tooltip">i
              <span>{{ $t('h4h.roomcashTooltipText') }}</span>
            </b>
          </span>
        </span>
        <span class="striked-off-price">
          <CurrencyConvert
            :amount="((price / hotel.stayDuration) + rewardPointsPerNight)*rate"
            :usd="(price / hotel.stayDuration + rewardPointsPerNight).toString()"
            :currency="currency"
          />
        </span>
        <span class="price">
          <CurrencyConvert
            :amount="(price / hotel.stayDuration)*rate"
            :usd="(price / hotel.stayDuration).toString()"
            :currency="currency"
          />
        </span>
      </div>
      <div v-else-if="hasStayDuration">
        <span class="price">
          <CurrencyConvert
            :amount="(price / hotel.stayDuration)*rate"
            :usd="(price / hotel.stayDuration).toString()"
            :currency="currency"
          />
        </span>
      </div>
      <div v-else>
        <span class="price">
          <CurrencyConvert
            :amount="(price-rewardPoints)*rate"
            :usd="(price-rewardPoints).toString()"
            :currency="currency"
          />
        </span>
      </div>
      <span
        v-if="hasStayDuration"
        class="per-night"
      >{{ $t('h4h.perNight') }}</span>
      <span
        v-if="hasStayDuration && checkIn != 'Invalid date' && checkOut != 'Invalid date'"
        class="total-price"
      >
        <CurrencyConvert
          :amount="(price - rewardPoints)*rate"
          :usd="(price - rewardPoints).toString()"
          :currency="currency"
        />
        {{ $t('h4h.tbd') }}
      </span>
      <span
        v-else-if="hasStayDuration && (rewardPoints > 0)"
        class="total-price"
      >
        <CurrencyConvert
          :amount="(price - rewardPoints)*rate"
          :usd="(price - rewardPoints).toString()"
          :currency="currency"
        />
        {{ $t('h4h.tbd') }}
      </span>
      <span
        v-else
        class="total-price"
      >
        <CurrencyConvert
          :amount="price*rate"
          :usd="price"
          :currency="currency"
        />
        {{ $t('h4h.tbd') }}
      </span>
    </div>
    <div class="retail-hotel-purchasing">
      <div class="retail-hotel-show-btn">
        <div
          class="btn btn-secondary room-select-btn"
          :data-hotel-name="hotel.name"
          @click="selectSpecifiedHotel(hotel)"
        >
          {{ $t('h4h.chooseRoom') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { CurrencyConvert, CurrencyApi } from '../../../default/currency_convert'
import {trackHotelIndex, trackHotelOverview} from '../h4h_hotels/shared_hotel_card_functions'
import RoomcashScaleContainer from './roomcash_scale_container.vue'

import moment from 'moment'
/* global locale */
export default {
  components: {
    CurrencyConvert,
    RoomcashScaleContainer
  },
  props:{
    hotel: {
      default: () => {},
      type: Object,
    },
    rate: {
      default: 0,
      type: Number
    },
    currency: {
      default: 'USD',
      type: String
    }
  },
  computed: {
    ...mapGetters('hotel', ['getLowestValue', 'getTBD', 'getLowestPriceDiscountedRoom']),
    price() {
      return this.getLowestValue(this.hotel.id)
    },
    lowestDiscountedRoom(){
      return this.getLowestPriceDiscountedRoom(this.hotel.id)
    },
    rewardPoints(){
      if(this.lowestDiscountedRoom){
        return this.lowestDiscountedRoom.rewardPoints
      } else {
        return 0
      }
    },
    rewardPointsPerNight() {
      return (this.rewardPoints / this.hotel.stayDuration)
    },
    formatRewardPointsPerNight() {
      return CurrencyApi.formatAmountNoCents((this.rewardPoints / this.hotel.stayDuration )* this.rate, this.currency)
    },
    hasStayDuration(){
      return this.hotel.stayDuration > 0
    },
    standardH4HForm(){
      return !this.order.blank_h4h_form
    },
    order(){
      return this.$store.state.order.order
    },
    checkIn(){
      moment.locale(locale)
      if (this.isAusOpen) {
        return moment(this.hotel.checkIn).format('LL')
      } else {
        return moment(this.hotel.checkIn).format('YYYY-MM-DD')
      }
    },
    checkOut(){
      moment.locale(locale)
      if(this.isAusOpen) {
        return moment(this.hotel.checkOut).format('LL')
      } else {
        return moment(this.hotel.checkOut).format('YYYY-MM-DD')
      }

    },
    isAusOpen() {
      return (window.brand_name == 'The International')
    },
    numberOfNights(){
      if(this.hasStayDuration) {
        return `for ${this.hotel.stayDuration} nights`
      } else {
        return 'total'
      }
    },
  },

  methods:{
    ...mapMutations('hotel', ['selectHotel', 'deselectHotel', 'setRetailHotelStatus']),

    selectSpecifiedHotel(hotel){
      this.selectHotel(hotel)
      this.setRetailHotelStatus(true)
      this.manipulateHistory(hotel)

      trackHotelOverview(hotel)
    },
    manipulateHistory(hotel){
      window.history.pushState(hotel.id, null, `hotels#${hotel.id}`)
      window.addEventListener('popstate', (e)=>{
        if(e.state !== null) {
          this.selectHotel(hotel)
          trackHotelOverview(hotel)
        } else {
          this.selectHotel(null)
          trackHotelIndex()
        }
      })
    },
  }
}
</script>
