<template>
  <div class="retail-hotel-views">
    <!-- <div class="list-view-option">
      <i class="fa fa-list-ul" /> List View
    </div>
    <div
      v-if="standardH4HForm || hotelsPresent"
      class="map-view-option"
      @click="displayMap"
    >
      <i class="fa fa-map-o" /> Map View
    </div> -->
    <div
      class="sort-filter-option"
      @click="toggleMobileFilter"
    >
      <i
        class="fa fa-sliders"
        aria-hidden="true"
      /> Sort/Filter
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    contracted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hotelsPresent(){
      return this.$store.state.hotel.hotels.length > 0
    },
    order(){
      return this.$store.state.order.order
    },
    standardH4HForm(){
      return !this.order.blank_h4h_form
    }
  },

  methods: {
    ...mapMutations('hotel', ['showMap', 'setContractedOnlyMap']),
    displayMap() {
      if (this.contracted) {
        this.setContractedOnlyMap(this.contracted)
      }
      this.showMap(true)
    },
    toggleMobileFilter(){
      this.$store.commit('hotel/toggleFilter')
      document.body.classList.toggle('end-state')
    }
  }
}
</script>
