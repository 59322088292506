<template>
  <div class="roomcash-scale-container">
    <div
      id="roomcash-bar-container"
      class="roomcash-bar-container"
    >
      <span :class="[ percentClass, 'bar']" />
    </div>
    <div class="container-lower">
      <div class="amount">
        <div class="cash-text">
          <span class="rc-value">
            <CurrencyConvert
              :amount="(rewardPoints)*rate"
              :usd="(rewardPoints).toString()"
              :currency="currency"
            />
          </span>
          <p class="per-night-box">
            <span>RoomCash</span>
            <span class="tooltip-wrapper">
              <span>
                <b class="roomcash-amount-tooltip">?
                  <span>Maximum amount of your RoomCash we can apply.</span>
                </b>
              </span>
            </span>
          </p>
        </div>
      </div>
      <div class="your-cash-amount">
        <div class="cash-text">
          <span class="yc-value">
            <!-- UNCOMMENT BELOW IF THEY WANT PRICE IN TERMS OF NIGHTLY PRICE AND MAKE OTHER CURRENCY CONVERT A V-ELSE-->

            <!-- <CurrencyConvert
              v-if="hasStayDuration"
              :amount="(price / hotel.stayDuration)*rate"
              :usd="(price / hotel.stayDuration).toString()"
              :currency="currency"
            /> -->
            <CurrencyConvert
              :amount="(price)*rate"
              :usd="(price).toString()"
              :currency="currency"
            />


          </span>
          <p class="per-night-box">
            <span>Your Cash</span>
            <span class="tooltip-wrapper">
              <span>
                <b class="roomcash-amount-tooltip">?
                  <span>How much you'll pay when you book today.</span>
                </b>
              </span>
            </span>
            <br>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyConvert } from '../../../default/currency_convert'

export default {
  components: {
    CurrencyConvert
  },
  props:{
    rewardPoints: {
      default: 0,
      type: Number,
    },
    rate: {
      default: 0,
      type: Number
    },
    price: {
      default: '0',
      type: String
    },
    currency: {
      default: 'USD',
      type: String
    }
  },

  computed:{
    percentClass(){
      if (50 >= this.rewardPoints &&  this.rewardPoints>=40){

        return 'fourty'
      }
      else if(39>=this.rewardPoints &&  this.rewardPoints >=35){

        return 'thirty-five'
      }
      else if(34>=this.rewardPoints &&  this.rewardPoints >=30){
        return 'thirty'
      }
      else if(29>=this.rewardPoints &&  this.rewardPoints >=25){
        return 'twenty-five'
      }
      else if(24>=this.rewardPoints &&  this.rewardPoints >=20){
        return 'twenty'
      }
      else if(19>=this.rewardPoints &&  this.rewardPoints >=10){

        return 'fifteen'
      } else if(this.rewardPoints >=1){
        return 'ten'
      } else {
        return 'zero'
      }
    }
  },
}

</script>
