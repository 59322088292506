<template>
  <div class="hotel-and-ticket-icon">
    <div class="hotel-ticket-icons">
      <div class="icon-container">
        <TicketIcon />
      </div>
      <div class="plus-icon">
        <i class="fa fa-plus" />
      </div>
      <div class="large-hotel-icon-container">
        <HotelIcon />
      </div>
    </div>
    <div class="accomodation-statement">
      <span class="bold-statment">{{ $t("h4h.nowChooseAccomodation") }}</span>
      <span>{{ $t("h4h.toAccompanyTicket") }}</span>
    </div>
  </div>
</template>

<script>
import TicketIcon from './ticket-icon'
import HotelIcon from './hotel-icon'

export default {
  components:{
    TicketIcon,
    HotelIcon,
  }
}
</script>
