<template>
  <div class="retail-hotel-filter column">
    <div class="sort-by-section-container">
      <i
        class="fa fa-times close-filter-btn"
        @click="toggleMobileFilter"
      />
      <div class="filter-section-title">
        {{ $t('h4h.sortBy') }}
      </div>
      <label for="distanceSort">
        <input
          id="distanceSort"
          v-model="sortBy"
          class="filter-sort-field"
          type="radio"
          value="distance"
        >
        <i class="fa fa-circle" />
        {{ $t('h4h.distance') }}
      </label>
      <br>
      <label for="priceSort">
        <input
          id="priceSort"
          v-model="sortBy"
          class="filter-sort-field"
          type="radio"
          value="price"
        >
        <i class="fa fa-circle" />
        {{ $t('h4h.price') }}
      </label>
    </div>
    <div class="filter-by-section-container">
      <div class="filter-section-title">
        {{ $t('h4h.filterBy') }}
      </div>
      <!-- <div class="star-rating-filter-container">
        <p class="section-subtitle">
          {{ $t('h4h.starRating') }}
        </p>
        <div
          id="star-rating-field-container"
          class="star-rating-field-container"
        >
          <label
            id="5-Stars"
            class="star-rating-field"
          >
            <input
              v-model="starRatings"
              class="star-btn"
              type="checkbox"
              value="5 Stars"
              @click="ratingSelected"
            >
            5
            <i class="fa fa-star" />
          </label>
          <label
            id="4-Stars"
            class="star-rating-field"
          >
            <input
              v-model="starRatings"
              class="star-btn"
              type="checkbox"
              value="4 Stars"
              @click="ratingSelected"
            >
            4
            <i class="fa fa-star" />
          </label>
          <label
            id="3-Stars"
            class="star-rating-field"
          >
            <input
              v-model="starRatings"
              class="star-btn"
              type="checkbox"
              value="3 Stars"
              @click="ratingSelected"
            >
            3
            <i class="fa fa-star" />
          </label>
          <label
            id="2-Stars"
            class="star-rating-field"
          >
            <input
              v-model="starRatings"
              class="star-btn"
              type="checkbox"
              value="2 Stars"
              @click="ratingSelected"
            >
            2
            <i class="fa fa-star" />
          </label>
          <label
            id="1-Stars"
            class="star-rating-field"
          >
            <input
              v-model="starRatings"
              class="star-btn"
              type="checkbox"
              value="1 Stars"
              @click="ratingSelected"
            >
            1
            <i class="fa fa-star" />
          </label>
        </div>
      </div> -->
      <div class="amenities-filter-container">
        <p class="section-subtitle">
          {{ $t('h4h.amenities') }}
        </p>
        <div class="amenities-filter">
          <label for="airportShuttle">
            <input
              id="airportShuttle"
              v-model="reqAirportShuttle"
              class="filter-sort-field"
              type="checkbox"
              name="Shuttle"
              value="airport shuttle (on site)"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.airportShuttle') }}
          </label>
          <br>
          <label for="breakfast">
            <input
              id="breakfast"
              v-model="reqBreakfast"
              class="filter-sort-field"
              type="checkbox"
              name="Breakfast"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.breakfast') }}
          </label>
          <br>
          <label for="businessCenter">
            <input
              id="businessCenter"
              v-model="reqBusinessCenter"
              class="filter-sort-field"
              type="checkbox"
              name="Business Center"
              value="business center (on site)"
            >
            <i class="fa fa-check" />
            Business Center
          </label>
          <br>
          <label for="fitnessCenter">
            <input
              id="fitnessCenter"
              v-model="reqFitnessCenter"
              class="filter-sort-field"
              type="checkbox"
              name="FitnessCenter"
              value="fitness center (on site)"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.fitness') }}
          </label>
          <br>
          <label for="internet">
            <input
              id="internet"
              v-model="reqInternet"
              class="filter-sort-field"
              type="checkbox"
              name="Internet"
              value="internet access (on site)"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.internet') }}</label>
          <br>
          <label for="pets">
            <input
              id="pets"
              v-model="reqPetsAllowed"
              class="filter-sort-field"
              type="checkbox"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.pets') }}
          </label>
          <br>
          <label for="pool">
            <input
              id="pool"
              v-model="reqPool"
              class="filter-sort-field"
              type="checkbox"
              name="Pool"
              value="pool (on site)"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.pool') }}
          </label>
          <br>
          <label for="resturant">
            <input
              id="resturant"
              v-model="reqRestaurant"
              class="filter-sort-field"
              type="checkbox"
              name="Resturant"
              value="resturant on site (on site)"
            >
            <i class="fa fa-check" />
            {{ $t('h4h.restaurant') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import mixin from './hotel_availability.vue'

export default {

  mixins: [mixin],

  data() {
    return {
      reqAirportShuttle: false,
      reqBreakfast: false,
      reqBusinessCenter: false,
      reqFitnessCenter: false,
      reqInternet: false,
      reqKitchenKitchenette: false,
      reqPetsAllowed: false,
      reqPool: false,
      reqRestaurant: false,
      sortBy: String,
      starRatings: []
    }
  },

  computed: mapGetters('hotel', {
    retailHotels: 'getRetailHotels',
    filteredHotels: 'getFilteredHotels'
  }),

  watch: {

    reqAirportShuttle: function() {
      this.filterHotels()
    },

    reqBreakfast: function() {
      this.filterHotels()
    },

    reqBusinessCenter: function() {
      this.filterHotels()
    },

    reqFitnessCenter: function() {
      this.filterHotels()
    },

    reqInternet: function() {
      this.filterHotels()
    },

    reqPetsAllowed: function() {
      this.filterHotels()
    },

    reqPool: function() {
      this.filterHotels()
    },

    reqRestaurant: function() {
      this.filterHotels()
    },

    starRatings: function() {
      this.filterHotels()
    },

    sortBy: function() {
      this.hotelSort()
    }
  },

  mounted() {
    if(document.getElementById('searchParams')) {
      this.setStarFilterValues(),
      this.setAmenityFilterValues()
    }
  },

  methods: {
    ...mapMutations('hotel', [
      'clearExcludedHotels',
      'hasHotelResults',
      'populateExcludedHotels',
      'populateFilteredHotels',
      'reloadHotels',
      'setFilteredHotels',
      'setRetailLoading',
      'sortHotelsByDistance',
      'sortHotelsByPrice',
      'toggleFilter'
    ]),

    setStarFilterValues(){
      const searchParams = document.getElementById('searchParams').dataset
      const propertyclasses = searchParams.propertyclasses
      if(propertyclasses.length > 0){
        propertyclasses.split(',').forEach(value => {
          Array.from(document.getElementsByClassName('star-rating-field-container')).forEach(container=>{
            let x = Array.from(container.children).find( label => {
              return label.id == this.classValueMap[parseInt(value)]
            })
            this.starRatings.push(this.classValueMap[parseInt(value)])
            x.classList.add('ratingSelected')
          })
        })}
    },

    setAmenityFilterValues() {
      const searchParams = document.getElementById('searchParams').dataset
      const propertyamenities = searchParams.propertyamenities
      if(propertyamenities.length > 0){
        propertyamenities.split(',').forEach( value => {
          switch (value){
          case 'reqAirportShuttle':
            this.reqAirportShuttle = true
            break
          case 'reqBreakfast':
            this.reqBreakfast = true
            break
          case 'reqBusinessCenter':
            this.reqBusinessCenter = true
            break
          case 'reqKitchenKitchenette':
            this.reqKitchenKitchenette = true
            break
          case 'reqFitnessCenter':
            this.reqFitnessCenter = true
            break
          case 'reqInternet':
            this.reqInternet = true
            break
          case 'reqPets':
            this.reqPetsAllowed = true
            break
          case 'reqPool':
            this.reqPool = true
            break
          case 'reqRestaurant':
            this.reqRestaurant = true
            break
          default:
            break
          }
        })
      }
    },

    filterHotels(){
      this.setRetailLoading(true)
      this.reloadHotels()
      this.clearExcludedHotels()
      this.populateFilteredHotels()
      this.filteredHotels.forEach((hotel) => {
        // this.filterByStarRating(hotel)
        this.filterByAmenities(hotel)
      })
      this.hasHotelResults()
      this.setFilteredHotels()
      this.hotelSort()
      this.setRetailLoading(false)
      // this.scrollToAlert()
    },

    hotelSort() {
      if(this.sortBy === 'distance') {
        this.sortHotelsByDistance()
      } else if(this.sortBy === 'price') {
        this.sortHotelsByPrice()
      } else {
        return
      }
    },

    // Star Ratings are no longer displayed
    // filterByStarRating(hotel){
    //   if(!this.starRatings.length == 0 || !this.starRatings.length == 5) {
    //     this.includesStarRating(hotel)
    //   }
    // },

    // includesStarRating(hotel){
    //   if(!this.starRatings.includes(hotel.starRating)) {
    //     this.populateExcludedHotels(hotel)
    //   }
    // },

    filterByAmenities(hotel){
      this.allowPets(hotel)
      this.includesBreakfast(hotel)
      this.hasBusinessCenter(hotel)
      this.hasFitnessCenter(hotel)
      this.hasInternetAccess(hotel)
      this.hasPool(hotel)
      this.hasRestaurant(hotel)
      this.hasAirportShuttle(hotel)
    },

    hasAirportShuttle(hotel){
      if(this.reqAirportShuttle && !hotel.airportShuttle) {
        this.populateExcludedHotels(hotel)
      }
    },

    allowPets(hotel){
      if(this.reqPetsAllowed && !hotel.petsAllowed) {
        this.populateExcludedHotels(hotel)
      }
    },

    includesBreakfast(hotel){
      if(this.reqBreakfast && !hotel.breakfast) {
        this.populateExcludedHotels(hotel)
      }
    },

    hasBusinessCenter(hotel){
      if(this.reqBusinessCenter && !hotel.businessCenter) {
        this.populateExcludedHotels(hotel)
      }
    },

    hasFitnessCenter(hotel){
      if(this.reqFitnessCenter && !hotel.hasFitnessCenter) {
        this.populateExcludedHotels(hotel)
      }
    },

    hasInternetAccess(hotel) {
      if(this.reqInternet && !hotel.internetAccess) {
        this.populateExcludedHotels(hotel)
      }
    },

    hasPool(hotel) {
      if(this.reqPool && !hotel.pool) {
        this.populateExcludedHotels(hotel)
      }
    },

    hasRestaurant(hotel) {
      if(this.reqRestaurant && !hotel.restaurant) {
        this.populateExcludedHotels(hotel)
      }
    },


    ratingSelected(event){
      event.target.parentNode.classList.toggle('ratingSelected')
    },

    toggleMobileFilter(){
      this.toggleFilter()
      document.body.classList.toggle('end-state')
    }
  }
}
</script>
