<template>
  <div
    v-if="(room.inventory > 0) && room.active"
    class="retail-room-card-container"
  >
    <div class="retail-room-details">
      <div class="retail-room-title">
        <h4>{{ room.name }}</h4>
      </div>
      <p
        v-if="hotel.contracted && notEnoughRooms"
        class="contracted-rooms-remaining"
      >
        {{ room.inventory == 1 ? $t('h4h.roomRemaining', {
          quantity: room.inventory
        }) : $t('h4h.roomsRemaining', {
          quantity: room.inventory
        }) }}
      </p>
      <p>{{ room.description }}</p>
    </div>
    <div class="retail-room-purchasing">
      <div class="retail-room-pricing">
        <span
          class="price"
        >
          <b v-if="hasStayDuration">
            <CurrencyConvert
              :amount="((room.total* chosenRoomQty) / hotel.stayDuration) * rate"
              :usd="(room.total * chosenRoomQty) / hotel.stayDuration"
              :currency="currency"
            />
          </b>
          <b v-else>
            <CurrencyConvert
              :amount="room.total * rate"
              :usd="room.total"
              :currency="currency"
            />
          </b>
        </span>
        <span
          v-if="hasStayDuration"
          class="total-price"
        >
          <CurrencyConvert
            :amount="room.total * chosenRoomQty * rate"
            :usd="room.total * chosenRoomQty"
            :currency="currency"
          />
          <span class="hotel-show-stay-length">
            {{ (getRetailHotelStatus || !getTBDStatus) ? $t('h4h.roomTotal', {
              quantity: hotel.stayDuration
            }) : $t('h4h.tbd') }}
          </span>
        </span>
      </div>
      <div
        class="btn btn-secondary btn-size"
        :class="disableButton ? 'btn-disabled' : ''"
        :disable="disableButton || disableForSubmission"
        @click="changeOrderItem()"
      >
        {{ $t('h4h.selectRoom') }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../cart/cart_api'
import { CurrencyConvert } from '../../../default/currency_convert'
import { mapState } from 'vuex'
import { mapMutations, mapActions, mapGetters } from 'vuex'

// import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    CurrencyConvert
  },

  props: {
    checkIn: {
      type: Date,
      default: null
    },
    checkOut: {
      type: Date,
      default: null
    },
    room: {
      type: Object,
      default: null
    },
    rate: {
      type: Number,
      default: 1
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },

  data() {
    return {
      disableForSubmission: false
    }
  },

  computed: {
    ...mapGetters('hotel', ['getTBDStatus', 'getRetailHotelStatus']),
    ...mapState('hotel', {
      hotel: state => state.hotel,
      roomQty: state => state.roomQty
    }),
    order(){
      return this.$store.state.order.order
    },
    hasStayDuration(){
      return this.hotel.stayDuration > 0
    },
    notEnoughRooms() {
      return this.room.inventory <= this.$store.state.packages.packageQty
    },
    disableButton() {
      return this.disableForSubmission ? true : false
    },
    packageId(){
      return this.order.items[0].package_id
    },
    orderItemId(){
      let itemWithoutRoom = this.order.items.find( item => {
        return !item.room_quantity
      })
      if(itemWithoutRoom){
        return itemWithoutRoom.id
      } else {
        return this.order.items[0].id
      }
    },
    packageQty(){
      return this.order.item_count
    },
    chosenRoomQty(){
      return this.$store.state.hotel.roomQty
    }
  },

  methods: {
    ...mapMutations('cart', [
      'displayCart'
    ]),
    ...mapActions('order', ['getBackendOrder']),

    postRetailBody(amount) {
      return JSON.stringify({
        order_item: {
          city: this.hotel.city,
          contracted: this.hotel.contracted,
          hotel_address: this.hotel.addressOne,
          hotel_address_2: this.hotel.addressTwo,
          hotel_check_in_date: moment(this.checkIn).format('DD/MM/YYYY'),
          hotel_check_out_date:moment(this.checkOut).format('DD/MM/YYYY'),
          hotel_check_in_time: this.hotel.checkInTime,
          hotel_check_out_time: this.hotel.checkOutTime,
          hotel_city: this.hotel.city,
          hotel_country: this.hotel.country,
          hotel_id: this.hotel.id,
          hotel_latitude: this.hotel.latitude,
          hotel_longitude: this.hotel.longitude,
          hotel_name: this.hotel.name,
          hotel_phone: this.hotel.phone,
          hotel_state: this.hotel.state,
          hotel_total_price: amount,
          hotel_zip: this.hotel.zip,
          id: this.orderItemId,
          package_id: this.packageId,
          quantity: this.packageQty,
          room_quantity: this.$store.state.hotel.roomQty,
          room_code: this.room.roomCode,
          rate_plan_code: this.room.ratePlan,
          tax_amount: this.room.taxAmount,
          gateway: this.room.gateway,
          gateway_fee: this.room.gatewayFee,
          room_cost_price: this.room.nightlyRate,
          currency_code: this.room.currencyCode,
          booking_fee: this.room.bookingFee
        }
      })
    },
    postContractedBody(stayId) {
      return JSON.stringify({
        hotel: {
          hotel_id: this.hotel.id,
          room_quantity: this.$store.state.hotel.roomQty,
          stay_id: stayId
        }
      })
    },
    async changeOrderItem() {
      if (this.disableButton || this.disableForSubmission) { return }
      this.disableForSubmission = true
      this.errs = null
      let res = null

      if (this.hotel.contracted) {
        res = await Api.addContractedToCart(
          this.orderItemId,
          this.postContractedBody(this.room.stayId)
        )
      } else {
        res = await Api.addH4HHotelToCart(
          this.orderItemId,
          this.postRetailBody(this.room.total)
        )
      }

      if (res.id) {
        this.getBackendOrder().then( () => this.displayCart())
      } else {
        this.disableForSubmission = false
        this.errs = res.errors || this.$t('formPackage.error')
      }
    }
  }
}
</script>
